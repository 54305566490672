import { convertToJsonAndHandleErrors, getToken } from '../utils';
import { API_URL } from '../../constants';
import { organization } from '../../redux/reducers/organization';
import { calculateLastActive } from '../utils/tools';

/**
 *  ORGANIZATION
 */

export const createOrganization = (data) => {
  // organization.isPublic= organization.isVisible;
  return fetch(`${API_URL}/organization/create`, {
    method: 'POST',
    headers: {
      /* Accept: "application/json, text/plain, *!/!*",
      "Content-Type": "application/json",*/
      json: true,
      Authorization: getToken(),
    },
    body: data,
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getOrganization = () => {
  return fetch(`${API_URL}/organization/get`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const getPublicOrganization = () => {
  return fetch(`${API_URL}/organization/publicOrganizations`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateOrganization = (formData) => {
  //organization.isPublic= organization.isVisible;
  return fetch(`${API_URL}/organization/update`, {
    method: 'PUT',
    headers: {
      Accept: { 'Content-Type': 'multipart/form-data' },
      json: true,
      Authorization: getToken(),
    },
    body: formData,
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateOrganizationSettings = (organizationSettings) => {
  return fetch(`${API_URL}/organization/updateSettings`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(organizationSettings),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteOrganization = () => {
  return fetch(`${API_URL}/organization/delete`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const deletePersonalEmails = () => {
  return fetch(`${API_URL}/organization/lead/deletePersonalEmails`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

/**
 *  INVITATIONS
 */
export const inviteMember = (memberInfos) => {
  return fetch(`${API_URL}/organization/invitation/invite`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(memberInfos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

/**
 *  MEMBERS
 */

export const getMembers = (filters) => {
  return fetch(`${API_URL}/organization/member/getAll`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(filters),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getMember = () => {
  return fetch(`${API_URL}/organization/member/get`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateMemberSettings = (settings) => {
  return fetch(`${API_URL}/organization/member/updateSettings`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(settings),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateMemberInfos = (settings) => {
  return fetch(`${API_URL}/organization/member/updateInfos`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(settings),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const deleteMember = (memberInfos) => {
  return fetch(`${API_URL}/organization/member/delete`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(memberInfos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const leaveOrganization = () => {
  return fetch(`${API_URL}/organization/member/leave`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const changeOwnerSeat = (newSeatEmail) => {
  return fetch(`${API_URL}/organization/member/changeOwnerSeat`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(newSeatEmail),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

/*JOIN*/
export const joinOrganization = (infos) => {
  return fetch(`${API_URL}/organization/join/send`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getALLJoinsRequests = () => {
  return fetch(`${API_URL}/organization//join/all`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const approveJoinRequest = (infos) => {
  return fetch(`${API_URL}/organization/join/approve`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const declineJoinRequest = (infos) => {
  return fetch(`${API_URL}/organization/join/refuse`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

/*INVITATIONs*/

export const getALLInvitations = () => {
  return fetch(`${API_URL}/organization/invitation/all`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getNewInvitations = () => {
  return fetch(`${API_URL}/organization/invitation/new`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const acceptInvitation = (infos) => {
  return fetch(`${API_URL}/organization/invitation/accept`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const declineInvitation = (infos) => {
  return fetch(`${API_URL}/organization/invitation/refuse`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const deleteInvitation = (infos) => {
  return fetch(`${API_URL}/organization/invitation/delete`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const createPhoneActivity = (infos) => {
  return fetch(`${API_URL}/organization/activities/phone`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getOrgAdmins = (infos) => {
  return fetch(`${API_URL}/organization/member/admins`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(infos),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const warnAdmin = () => {
  return fetch(`${API_URL}/organization/warnAdmin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getMembersOutsideWorkspace = (data) => {
  return fetch(`${API_URL}/organization/member/getAllSpentCreditDate`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(data),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      /* const newData = data.members.map((d) => {
        return { ...d, clientDate: calculateLastActive(d.clientDate) };
      });
      return { data: { ...data, members: newData }, statusCode };*/
      return { data, statusCode };
    });
};

export const getSubOrganizations = () => {
  return fetch(`${API_URL}/organization/subOrganizations`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const getMembersWithoutWorkSpace = () => {
  return fetch(`${API_URL}/organization/member/nonMembers`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
