import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useBecomeMember } from '../../Home.hooks';
import { organization } from '../../../../redux/reducers/organization';
import { createOrganization, getOrganization } from '../../../../services/api/organization.api';
import { toast } from 'react-toastify';
import { actions } from '../../../Organization/store/Organization.store';
import { capitalizeFirstLetter } from '../../../../services/utils/tools';

export default function CreateWorkSpaceSection({ organization, user }) {
  const { func, states, setters } = useBecomeMember(organization);
  const { invitations } = states;
  const { handleAcceptInvitation, handleDeclineInvitation } = func;
  const onCreateWorkspace = async (e) => {
    e.preventDefault();
    let domainName = user.email.split('@')[1];
    domainName = domainName.split('.');
    domainName.pop();
    domainName = domainName.join('');
    const organization = {
      name: capitalizeFirstLetter(domainName) + ' Workspace',
      domains: [user.email.split('@')[1]],
    };
    const formData = new FormData();
    formData.append('picture', null);
    formData.append('organization', JSON.stringify(organization));
    const { data, statusCode } = await createOrganization(formData);
    if (statusCode === 200) {
      toast.success('Workspace  successfully created');
      setTimeout(() => {
        window.location = '/home/';
      }, 2000);
    } else {
      toast.error(data.messageTranslated || data.message);
    }
  };
  return (
    <div
      id="dashboard_tab"
      className="tab-pane active show global-fade-in "
      style={{ marginTop: 20 }}
    >
      <div className={'onboarding-content'}>
        {invitations &&
          invitations.map((invitation, i) => (
            <div className={'invitations-received'} key={i} style={{ boxShadow: 'none' }}>
              <div>
                <img src={invitation.inviterPicture || '/assets/images/ic_profile.png'} alt="" />
                <FormattedMessage
                  id={'home.invitedYouOn'}
                  values={{
                    inviter: <strong> {invitation.inviter} </strong>,
                    orgName: <strong> '{invitation.organizationId.name}' </strong>,
                  }}
                />
              </div>
              <div>
                <button onClick={() => handleAcceptInvitation(invitation)}>
                  <FormattedMessage id={'accept'} />
                </button>
                <button onClick={() => handleDeclineInvitation(invitation)}>
                  <FormattedMessage id={'decline'} />
                </button>
              </div>
            </div>
          ))}
      </div>
      <div
        className=" home-card home-card-center info-card p-20 account-usage-card"
        style={{ minHeight: '300px' }}
      >
        <img src="/assets/images/tasks-image.png" alt="" />
        <h2>
          <FormattedMessage id={'organization.youHavetoCreateAnOrganization'} />
        </h2>
        <p>
          <FormattedMessage id={'organization.youHavetoCreateAnOrganization.text'} />{' '}
        </p>
        <a href={'#'} onClick={onCreateWorkspace} className={'btn btn1'} style={{ color: '#fff' }}>
          <FormattedMessage id={'organization.createOrganization'} />
        </a>
      </div>
    </div>
  );
}
