export default [
  {
    id: 'emailValidation',
    required: true,
    done: false,
    freeCredits: [],
  },
  {
    id: 'pluginInstallation',
    required: true,
    done: false,
    freeCredits: [],
  },
  {
    id: 'linkedinValidation',
    required: true,
    done: false,
    freeCredits: [
      {
        creditType: 'workEmailCredits',
        amount: 100,
        isRecurrent: true,
      },
      {
        creditType: 'personalEmailCredits',
        amount: 5,
        isRecurrent: true,
      },
      {
        creditType: 'phoneCredits',
        amount: 5,
        isRecurrent: true,
      },
    ],
  },
  {
    id: 'phoneValidation',
    required: false,
    done: true,
    freeCredits: [
      {
        creditType: 'phoneCredits',
        amount: 5,
      },
    ],
  },
  {
    id: 'profileCompletion',
    required: false,
    done: false,
    freeCredits: [],
  },
  {
    id: 'leadSaved',
    required: false,
    done: false,
    freeCredits: [
      {
        creditType: 'exportCredits',
        amount: 10,
      },
    ],
  },
  {
    id: 'connectIntegration',
    required: false,
    done: false,
    freeCredits: [
      {
        creditType: 'exportCredits',
        amount: 50,
      },
    ],
  },
  {
    id: 'personalEmailDiscovered',
    required: false,
    done: false,
    freeCredits: [
      {
        creditType: 'personalEmailCredits',
        amount: 5,
      },
    ],
  },
  {
    id: 'phoneDiscovered',
    required: false,
    done: false,
    freeCredits: [
      {
        creditType: 'phoneCredits',
        amount: 5,
      },
    ],
  },
  {
    id: 'becomeMember',
    required: false,
    done: false,
    freeCredits: [
      {
        creditType: 'exportCredits',
        amount: 10,
        isRecurrent: true,
      },
    ],
  },
  {
    id: 'launchAutomation',
    required: false,
    done: false,
    freeCredits: [
      {
        creditType: 'exportCredits',
        amount: 30,
        isRecurrent: true,
      },
    ],
  },
];
