import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Store/auth.store';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Signin.css';
import { Field } from '../common/Field/Field';
import actions from '../Store/auth.actions';
import { checkEmail, login } from '../../../services/api/auth.api';
import { saveAsCookie } from '../../../services/utils';
import queryString from 'query-string';
import { isMobile, isMobileSafari } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { EXTENSION_ID } from '../../../constants';

function SigninForm({ intl }) {
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [isCheck, setCheckEmail] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const [mobilePage, setMobilePage] = useState({});
  useEffect(() => {
    let { redirect, email } = queryString.parse(window.location.search);
    if (email) {
      const encodedMail = btoa(email);
      window.googleTagManager('try-kaspr');
      return (window.location = '/signup/' + encodedMail);
    }
    if (redirect) {
      setCheckEmail(false);
    }
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const { data, statusCode } = await login(state.loginForm);
    if (statusCode === 200) {
      saveAsCookie(data.jwt);
      if (window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage) {
        window.chrome.runtime.sendMessage(EXTENSION_ID, {
          action: 'REFRESH_WHEN_CONNECTED',
          input: { isConnected: true },
        });
      }
      window.location = '/';
    } else {
      setCheckEmail(false);
      setError(data.messageTranslated || data.message);
    }
  };

  const handleCheckEmail = async (e) => {
    e.preventDefault();
    const { data, statusCode } = await checkEmail({
      email: state.loginForm.email.trim(),
      isMobile: isMobile || isMobileSafari,
    });
    if (statusCode === 200 && (isMobile || isMobileSafari)) {
      return setMobilePage({
        isMobilePage: true,
        isKasprUser: data.isKasprUser,
      });
    }
    if (statusCode >= 400) {
      setError(data.messageTranslated || data.message);
      return;
    }
    setError(null);
    if (data.isKasprUser) {
      setCheckEmail(false);
    } else {
      dispatch({
        type: actions.SIGNUP,
        payload: { email: state.loginForm.email },
      });
      const encodedMail = btoa(state.loginForm.email);
      window.location = '/signup/' + encodedMail;
    }
  };
  return (
    <>
      <FormWrapper intl={intl}>
        <form onSubmit={isCheck ? handleCheckEmail : handleOnSubmit}>
          <div className="form-blocks">
            <Field
              label={intl.formatMessage({ id: 'auth.workEmail' })}
              className={'active'}
              onChange={(email) => dispatch({ type: actions.LOGIN, payload: { email } })}
              value={state.loginForm.email}
              formType={'signin'}
            />
            {!isCheck && (
              <div>
                <Field
                  label={intl.formatMessage({ id: 'info.password' })}
                  onChange={(password) => dispatch({ type: actions.LOGIN, payload: { password } })}
                  type={hidePassword ? 'password' : 'text'}
                  formType={'signin'}
                  element={
                    <div className="forgot-btn">
                      <i
                        style={{
                          cursor: 'pointer',
                          color: '#797f99',
                        }}
                        onClick={() => setHidePassword(!hidePassword)}
                        className={hidePassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
                      >
                        {' '}
                      </i>
                    </div>
                  }
                />
                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                  <NavLink to="forgot" className="forgot-btn" style={{ marginRight: '10px' }}>
                    {intl.formatMessage({ id: 'auth.forgotPassword' })}
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          {error && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{ top: -10, width: '90%' }}
            >
              {error + ' '}
            </div>
          )}
          {isCheck ? (
            <div className="btn-grp text-center">
              <button className="btn btn-blue btn-block h-58" type={'submit'}>
                {intl.formatMessage({ id: 'auth.proceed' })}
              </button>
            </div>
          ) : (
            <div className="btn-grp text-center">
              <button className="btn btn-blue btn-block h-58" type={'submit'}>
                {intl.formatMessage({ id: 'auth.letsGo' })}
              </button>
            </div>
          )}
          {/* <div className="other-info text-center">
            <p className="info-btn">
              New to Kaspr?
              <NavLink to="signup" className="text-btn">
                Sign up
              </NavLink>
            </p>
          </div>*/}
        </form>
      </FormWrapper>
      <Footer intl={intl} />
      {mobilePage.isMobilePage && (
        <Redirect
          to={{
            pathname: '/checkdevice',
            state: { isKasprUser: mobilePage.isKasprUser },
          }}
        />
      )}
    </>
  );
}

function Footer({ intl }) {
  return (
    <footer>
      <div className="container">
        <ul className="footer-list">
          <li>©2023 Kaspr</li>
          <li>·</li>
          <li>
            <p>
              <a target="_blank" href="https://www.kaspr.io/privacy-policy">
                {intl.formatMessage({ id: 'auth.privacy' })}
              </a>{' '}
              &amp;{' '}
              <a target="_blank" href="https://kaspr.io/terms/">
                {intl.formatMessage({ id: 'auth.terms' })}
              </a>
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
}

function FormWrapper({ children, intl }) {
  return (
    <section className="signup-wrapper">
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <div
              className="white-bg padding40 border-radius-12 mx-auto mt-5"
              style={{ maxWidth: '450px' }}
            >
              <div className="form-heading-grp text-center">
                <h4 className="h4">{intl.formatMessage({ id: 'auth.welcome' })}</h4>
                <p>{intl.formatMessage({ id: 'auth.itsGreatToSeeYou' })}</p>
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default injectIntl(SigninForm);
