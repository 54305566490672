import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DASHBOARD_URL } from '../../../../constants';
import 'quill/dist/quill.snow.css';
import { SelectPicker } from '../../../../components';
import { getEmailSignature, sendEmail } from '../../../../services/api/integrations.api';
import EmailField from '../EmailField/EmailField';
import { toast, ToastContainer } from 'react-toastify';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getLeadFeatures } from '../../../../services/api/leads.api';
import { actions, LeadsContext } from '../../store/leads.store';

function SendEmail({
  toggle,
  setToggle,
  lead,
  handleTemplateToggle,
  handleTemplateChange,
  template,
  intl,
}) {
  const { state, dispatch } = useContext(LeadsContext);
  const [error, setError] = useState('');
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [toggleCcs, setToggleCcs] = useState(false);
  const [ccs, setCcs] = useState([]);
  const [cc, setCc] = useState('');
  const [toggleBccs, setToggleBccs] = useState(false);
  const [bccs, setBccs] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [emailsList, setEmailsList] = useState([]);

  const handleSendEmail = async () => {
    let ccsToAdd = cc && cc.length > 0 && cc.split(new RegExp('[,; ]+')).filter((el) => !!el);
    let bccsToAdd = bcc && bcc.length > 0 && bcc.split(new RegExp('[,; ]+')).filter((el) => !!el);
    sendEmail(
      {
        to: selectedRecipients,
        ccs: ccsToAdd && ccsToAdd.length ? [...ccs, ...ccsToAdd] : ccs,
        bccs: bccsToAdd && bccsToAdd.length ? [...bccs, ...bccsToAdd] : bccs,
        subject: template.subject,
        emailBody: template.template,
      },
      lead,
    ).then(async ({ statusCode, data }) => {
      if (statusCode !== 200) toast.error(data.message);
      else {
        toast.success('Email sent');
        setSelectedRecipients([]);
        setCcs([]);
        setBccs([]);
        setCc('');
        setBcc('');
        const { data: featuresData, statusCode: featuresStatusCode } = await getLeadFeatures({
          leadId: lead._id,
        });
        if (featuresStatusCode == 200)
          dispatch({ type: actions.LEAD_DETAILS, payload: featuresData });
      }
    });
    setToggle(false);
  };

  const addRecipient = async (recipient) => {
    if (selectedRecipients.indexOf(recipient) === -1)
      setSelectedRecipients((selectedRecipients) => [...selectedRecipients, recipient]);
  };

  const addCc = (e) => {
    if (
      (e &&
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.split(new RegExp('[,; ]+')).length > 1) ||
      (e && e.key === 'Enter')
    ) {
      let ccsToAdd = e.target.value.split(new RegExp('[,; ]+'));
      for (let ccToAdd of ccsToAdd)
        if (ccToAdd && ccs.indexOf(ccToAdd) === -1) {
          setCcs((ccs) => [...ccs, ccToAdd]);
        }
      setCc('');
    }
  };

  const addBcc = (e) => {
    if (
      (e &&
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.split(new RegExp('[,; ]+')).length > 1) ||
      (e && e.key === 'Enter')
    ) {
      let bccsToAdd = e.target.value.split(new RegExp('[,; ]+'));
      for (let bccToAdd of bccsToAdd)
        if (bccToAdd && bccs.indexOf(bccToAdd) === -1) {
          setBccs((bccs) => [...bccs, bccToAdd]);
        }
      setBcc('');
    }
  };

  const editCc = (recipient) => {
    setCcs((ccs) => ccs.filter((el) => el !== recipient));
    setCc(recipient);
  };

  const editBcc = (recipient) => {
    setBccs((bccs) => bccs.filter((el) => el !== recipient));
    setBcc(recipient);
  };

  const deleteRecipient = async (recipient) => {
    setSelectedRecipients((selectedRecipients) =>
      selectedRecipients.filter((el) => el !== recipient),
    );
  };

  const deleteCc = async (e, cc) => {
    if (e) e.stopPropagation();
    setCcs((ccs) => ccs.filter((el) => el !== cc));
  };

  const deleteBcc = async (e, bcc) => {
    if (e) e.stopPropagation();
    setBccs((bccs) => bccs.filter((el) => el !== bcc));
  };

  useEffect(() => {
    getEmailSignature().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        if (data && data.signature && data.signature.signature && data.signature.signature.length)
          handleTemplateChange(
            '<p><br/><br/>------------<br/></p>' + data.signature.signature,
            'template',
          );
      }
    });
  }, []);

  useEffect(() => {
    let emails = new Set();
    if (lead && lead.currentProEmail) emails.add(lead.currentProEmail);
    if (lead && lead.currentProEmails && lead.currentProEmails.length)
      lead.currentProEmails.forEach(emails.add, emails);
    if (lead && lead.currentPersonalEmail) emails.add(lead.currentPersonalEmail);
    if (lead && lead.currentPersonalEmails && lead.currentPersonalEmails.length)
      lead.currentPersonalEmails.forEach(emails.add, emails);
    setEmailsList([...emails]);
  }, [lead]);

  return (
    <Modal
      show={toggle}
      onHide={(e) => {
        setToggle(e);
        setSelectedRecipients([]);
        setCcs([]);
        setBccs([]);
        setCc('');
        setBcc('');
      }}
      className="modal-send-email"
    >
      <div className="email">
        <Modal.Header
          closeButton
          className="modal-header border-0"
          onClick={(e) => {
            setToggle(e);
          }}
          style={{ cursor: 'pointer' }}
        >
          <div className="modal-inner-header">
            <p className="title" style={{ fontSize: '20px', marginTop: 0 }}>
              <FormattedMessage id={'settings.sendEmail'} />
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body p-0">
          <div className="inner-content">
            <div
              style={{
                marginLeft: '5px',
                display: 'inline-block',
                marginBottom: '7px',
                width: '95%',
              }}
            >
              <div className={'capital'}>
                {' '}
                <FormattedMessage id={'settings.to'} />{' '}
              </div>{' '}
              :
              <div
                style={{
                  display: 'inline',
                  marginLeft: '5px',
                  marginBottom: '7px',
                }}
              >
                {selectedRecipients.length > 0 &&
                  selectedRecipients.map((recipient) => (
                    <span
                      className="leads-select-tag"
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                    >
                      {recipient}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => deleteRecipient(recipient)}
                      >
                        {' x'}{' '}
                      </span>
                    </span>
                  ))}
                {emailsList && emailsList.length > 0 && (
                  <div style={{ display: 'inline-block' }}>
                    <SelectPicker
                      values={lead && lead.emails}
                      id={'emails'}
                      onChange={addRecipient}
                      labelsStyle={{ width: '100%' }}
                      zIndex={100}
                      color={'grey'}
                      staticLabel={intl.formatMessage({
                        id: 'leads.gmail.selectOneOrMoreRecipients',
                      })}
                      doCloseOnSelect={true}
                      doNotCapitalize={true}
                    />
                  </div>
                )}
              </div>
              <div
                onClick={() => setToggleBccs(!toggleBccs)}
                style={{ display: 'inline', float: 'right', cursor: 'pointer' }}
              >
                <FormattedMessage id={'leads.gmail.bcc'} />
              </div>
              <div
                onClick={() => setToggleCcs(!toggleCcs)}
                style={{
                  display: 'inline',
                  float: 'right',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
              >
                Cc
              </div>
            </div>
            {(toggleCcs || ccs.length > 0) && (
              <div
                style={{
                  width: '95%',
                  outline: 'none',
                  border: 'none',
                  marginRight: '5px',
                  marginLeft: '5px',
                  paddingBottom: '5px',
                  marginBottom: '15px',
                  borderBottom: '1px solid',
                  borderColor: '#D4D4D4',
                }}
              >
                Cc:
                {ccs.length > 0 &&
                  ccs.map((recipient) => (
                    <span
                      className={
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          recipient,
                        )
                          ? 'leads-select-tag'
                          : 'leads-select-tag-red'
                      }
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                      onClick={() => editCc(recipient)}
                    >
                      {recipient}
                      <span style={{ cursor: 'pointer' }} onClick={(e) => deleteCc(e, recipient)}>
                        {' x'}{' '}
                      </span>
                    </span>
                  ))}
                <input
                  type="text"
                  style={{
                    overflow: 'hidden',
                    width: '80%;',
                    minWidth: '80%',
                    outline: 'none',
                    border: 'none',
                    marginLeft: '10px',
                    display: 'inline',
                  }}
                  value={cc}
                  placeholder={''}
                  onKeyDown={(e) => {
                    addCc(e);
                  }}
                  onChange={(e) => {
                    setCc(e.target.value);
                    addCc(e);
                  }}
                />
              </div>
            )}
            {(toggleBccs || bccs.length > 0) && (
              <div
                style={{
                  width: '95%',
                  outline: 'none',
                  border: 'none',
                  marginLeft: '5px',
                  paddingBottom: '5px',
                  marginBottom: '15px',
                  borderBottom: '1px solid',
                  borderColor: '#D4D4D4',
                }}
              >
                Bcc:
                {bccs.length > 0 &&
                  bccs.map((recipient) => (
                    <span
                      className={
                        /^([_a-zA-Z0-9_+-]+(\.[_a-zA-Z0-9_+-]+)*@[_a-zA-Z0-9_-]+(\.[_a-zA-Z0-9_-]+)*(\.[a-z|A-Z|0-9]{2,12}))$/.test(
                          recipient,
                        )
                          ? 'leads-select-tag'
                          : 'leads-select-tag-red'
                      }
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                      onClick={() => editBcc(recipient)}
                    >
                      {recipient}
                      <span style={{ cursor: 'pointer' }} onClick={(e) => deleteBcc(e, recipient)}>
                        {' x'}{' '}
                      </span>
                    </span>
                  ))}
                <input
                  type="text"
                  style={{
                    overflow: 'hidden',
                    width: '80%;',
                    minWidth: '80%',
                    outline: 'none',
                    border: 'none',
                    marginLeft: '10px',
                    display: 'inline',
                  }}
                  value={bcc}
                  placeholder={''}
                  onKeyDown={(e) => {
                    addBcc(e);
                  }}
                  onChange={(e) => {
                    setBcc(e.target.value);
                    addBcc(e);
                  }}
                />
              </div>
            )}
            <div
              style={{
                width: '95%',
                outline: 'none',
                border: 'none',
                marginLeft: '5px',
                paddingBottom: '5px',
                marginBottom: '15px',
                borderBottom: '1px solid',
                borderColor: '#D4D4D4',
              }}
            >
              <FormattedMessage id={'leads.gmail.subject'} /> :
              <input
                type="text"
                style={{
                  width: '80%',
                  outline: 'none',
                  border: 'none',
                  marginLeft: '10px',
                  display: 'inline',
                }}
                value={template.subject}
                placeholder={intl.formatMessage({ id: 'leads.gmail.subject' })}
                onChange={(e) => {
                  handleTemplateChange(e.target.value, 'subject');
                }}
              />
            </div>
            <div className="form-group editor" style={{ height: '200px', paddingBottom: '30px' }}>
              <EmailField
                placeholder={intl.formatMessage({
                  id: 'leads.gmail.writeEmail',
                })}
                theme="snow"
                value={template.template}
                onChange={(value) => {
                  handleTemplateChange(value, 'template');
                }}
              />
            </div>
            <div
              style={{
                color: ' #595f79',
                fontSize: '14px',
                letterSpacing: '0.24px',
                lineHeight: '22px',
              }}
            >
              <FormattedMessage id={'leads.gmail.youCanYourSignature'} />
              <a target="_blank" href={DASHBOARD_URL + '/settings?integration=true'}>
                {' '}
                <FormattedMessage id={'leads.gmail.gmailSIntegrationSettings'} />
              </a>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer border-0 ">
          <button
            className={'activebtn ' + (intl.locale === 'fr' ? 'btnwFrench' : 'btnw')}
            onClick={handleTemplateToggle}
            style={{ height: '35px', width: '200px', marginRight: '50%' }}
          />
          {template &&
          template.template &&
          template.template !== '<p><br></p>' &&
          template.subject &&
          selectedRecipients &&
          selectedRecipients.length > 0 ? (
            <a href="#" className="btn1" onClick={handleSendEmail}>
              <FormattedMessage id={'leads.gmail.send'} />
            </a>
          ) : (
            <a
              href="#"
              className="btn1 disabled"
              style={{ background: 'grey', textShadow: '-1px 1px 0 grey' }}
            >
              <FormattedMessage id={'leads.gmail.send'} />
            </a>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default injectIntl(SendEmail);
