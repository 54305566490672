import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  deleteOrganization,
  deletePersonalEmails,
  getOrganization,
  updateOrganization,
} from '../../../services/api/organization.api';
import { FormattedMessage, useIntl } from 'react-intl';
import InputTags from '../../../components/InputTags/InputTags';
import { PreLoader, SelectPicker } from '../../../components';
import './Workspace.css';
import { Modal } from 'react-bootstrap';
import queryString from 'query-string';
import DeleteMember from '../../Organization/Members/DeleteMember/DeleteMember';
import InputEmailTags from '../../../components/InputEmailTags/InputEmailTags';
export default function Workspace({ team, profile }) {
  const [error, setError] = useState(false);
  const [savedForm, setSavedForm] = useState(false);
  const [deleteEmailsFromLeadsModal, setDeleteEmailsFromLeadsModal] = useState(false);
  const [deleteTeamToggle, setDeleteTeamModal] = useState(false);
  const [deleteMemberPopUp, setDeleteMemberPopup] = useState(false);
  const [organization, setOrganization] = useState({
    domains: team.domains || [],
    name: team.name,
    image: team.picture,
    hidePersonalEmail: team.hidePersonalEmail,
  });
  const intl = useIntl();
  const [imageBase64, setImageBase64] = useState(null);
  const updateImage = async (e) => {
    let file = e.target.files[0],
      reader = new FileReader();
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
    if (!(file && acceptedImageTypes.includes(file.type))) return;
    setOrganization({
      ...organization,
      image: { file },
    });
    reader.onloadend = function () {
      setImageBase64(reader.result);
    };

    reader.readAsDataURL(file);
  };
  useEffect(() => {
    const { action } = queryString.parse(window.location.search);
    if (action == 'delete') {
      setDeleteTeamModal(true);
    }
  }, []);
  useEffect(() => {
    setOrganization({
      ...organization,
      domains: team.domains,
      isVisible: team.isPublic,
      name: team.name,
      image: team.picture,
      hidePersonalEmail: team.hidePersonalEmail,
    });
    if (team.hidePersonalEmail) {
      hidePersonalEmailCheckboxRef.current.checked = true;
    }
  }, [team]);

  const hidePersonalEmailCheckboxRef = useRef(null);
  const nameInputRef = useRef(null);
  const appendToFormAndSubmit = async (teamToUpdate) => {
    const file = teamToUpdate.image && teamToUpdate.image.file;
    const formData = new FormData();
    formData.append('picture', file);
    formData.append('organization', JSON.stringify(teamToUpdate));
    const { data, statusCode } = await updateOrganization(formData);
    if (statusCode === 200) {
      getOrganization().then(({ data, statusCode }) => {
        // dispatch({ type: actions.GET_ORGANIZATION, payload: data });
        if (statusCode == 200) {
          setOrganization({
            domains: data.organization.domains || [],
            name: data.organization.name,
            image: data.organization.picture,
            hidePersonalEmail: data.organization.hidePersonalEmail,
          });
        } else {
          window.location = '/workspace/createjoin';
        }
      });
    } else {
      setError(data.messageTranslated || data.message);
    }
    return { data, statusCode };
  };

  const handleCheckboxChange = async (e) => {
    let team = organization;
    if (e.target.name === 'hidePersonalEmail') {
      hidePersonalEmailCheckboxRef.current.checked = !!e.target.checked;
      setOrganization({
        ...organization,
        hidePersonalEmail: !!e.target.checked,
      });
      team = {
        ...organization,
        hidePersonalEmail: !!e.target.checked,
      };
    }
    await appendToFormAndSubmit(team);
  };
  const checkValidity = (domain) => {
    let domainRegex = /^[a-zA-Z0-9-]{0,61}\.([a-zA-Z]{2,})+$/;
    return domainRegex.test(domain);
  };
  const handleOnChangeDomains = (tags, txtValue) => {
    checkValidity(txtValue);
    let invalidsNumber = tags.filter((tag) => tag.isValid == false).length;
    let allInvalids = tags.some((tag) => tag.isValid);
    if (invalidsNumber) {
      setError(
        intl.formatMessage(
          { id: 'organization.youHaveEnteredInvalidDomain' },
          { value: invalidsNumber },
        ),
      );
    } else {
      setError(null);
    }
    if (
      (tags && tags.length && (tags.length > 1 || invalidsNumber !== 1) && allInvalids) ||
      checkValidity(txtValue)
    ) {
      setOrganization({
        ...organization,
        domains: tags.filter((tag) => tag.isValid).map((tag) => tag.value),
      });
      appendToFormAndSubmit({
        ...organization,
        domains: tags.filter((tag) => tag.isValid).map((tag) => tag.value),
      }).then();
    } else {
      setOrganization({
        ...organization,
        domains: [],
      });
    }
    // setOrganization({ ...organization, domains })
  };
  const handleOnSubmit = async () => {
    if (!organization.name) {
      nameInputRef.current.reportValidity();
    } else {
      const { statusCode } = await appendToFormAndSubmit(organization);
      setSavedForm(true);
      setTimeout(() => {
        setSavedForm(false);
        window.location.href = '/settings/workspace';
      }, 600);
    }
  };
  const handleDeleteEmailsFromLeads = (e) => {
    e.preventDefault();
    setDeleteEmailsFromLeadsModal(true);
  };

  const isAdmin = true;
  if (!team._id) return <PreLoader />;
  return (
    <div className="cn-organzation-wraper org-settings tab-pane active show" id="workspace_tab">
      <div className="">
        <div className="add-member-fields new-organization-ui">
          <div className={'info-card edit-name-picture'}>
            <div className={'form-edit-container'}>
              <div className={'name-pic'}>
                <div className="thumnail-upload-ui">
                  <figure>
                    <img
                      src={
                        imageBase64 ||
                        organization.image ||
                        '/assets/images/ic_organization active.svg'
                      }
                      alt=""
                    />
                  </figure>
                  <div className="thumbnail-content">
                    <p>
                      <FormattedMessage id={'organization.thumbnail'} />
                    </p>
                    <div className="upload-btn">
                      <input type="file" name="file" onChange={updateImage} />
                      <label>
                        + <FormattedMessage id={'organization.changeImage'} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group  ">
                  <p>
                    <FormattedMessage id={'organization.organizationName'} />
                  </p>
                  <input
                    ref={nameInputRef}
                    type="text"
                    required
                    placeholder="eg. Apple"
                    onChange={(e) =>
                      setOrganization({
                        ...organization,
                        name: e.target.value,
                      })
                    }
                    value={organization.name}
                  />
                </div>
              </div>
              {savedForm ? (
                <a
                  href="#"
                  className="btn1 btn48"
                  style={{ background: 'green' }}
                  onClick={(e) => e.preventDefault()}
                >
                  <FormattedMessage id={'successfullySaved'} />
                </a>
              ) : (
                <a href="#" className="btn1 btn48" onClick={handleOnSubmit}>
                  <FormattedMessage id={'save'} />
                </a>
              )}
            </div>
          </div>

          <div className={'info-card'}>
            <div className="account-type-listing">
              {/* <div className="choose-heading-ui">
                <label>
                  <FormattedMessage id={"organization.chooseType"} />
                </label>
              </div>*/}
              <div className="choose-type-listing">
                <div className="checkbox-wrap mb-10">
                  <label>
                    <div className="checkbox-content">
                      {/*<h6>
                        <FormattedMessage id={"organization.public"} />
                      </h6>*/}
                      <p>
                        <FormattedMessage id={'organization.public.text'} />
                      </p>
                    </div>
                  </label>
                </div>
                <div className="enter-domain-ui">
                  <div className="form-group">
                    <InputEmailTags
                      onChange={handleOnChangeDomains}
                      /* onChange={(domains) => {
                        setOrganization({ ...organization, domains });
                        appendToFormAndSubmit({
                          ...organization,
                          domains,
                        }).then();
                      }}*/
                      profile={profile}
                      domains={
                        organization.domains &&
                        organization.domains.map((domain) => ({
                          value: domain,
                          isValid: true,
                        }))
                      }
                      placeholder={intl.formatMessage({
                        id: 'organization.typeYourDomain',
                      })}
                      checkValidity={checkValidity}
                    />
                  </div>
                  <div className="suggestion-items">
                    <label className="suggestion-label">
                      <FormattedMessage id={'organization.examples'} />
                    </label>
                    <span className="sugeestion-tag-ui">kaspr.com</span>
                    <span className="sugeestion-tag-ui">kaspr.fr</span>
                  </div>
                  {error && (
                    <div className={'invalid-emails-error'}>
                      <img src="/assets/images/ic-alert-dark.svg" alt="" /> {error}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={'info-card'}>
            <div className="account-type-listing">
              <div className="choose-heading-ui">
                <label>
                  <FormattedMessage id={'organization.settings'} />
                </label>
              </div>
              <div
                className="choose-type-listing"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className="checkbox-wrap mb-10" style={{ width: 'max-content' }}>
                  <label>
                    <input
                      type="checkbox"
                      name={'hidePersonalEmail'}
                      ref={hidePersonalEmailCheckboxRef}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkbox-icon" />
                    <div className="checkbox-content">
                      <h6>
                        <FormattedMessage id={'organization.hidePersonalEmail'} />
                      </h6>
                      <p>
                        <FormattedMessage id={'organization.hidePersonalEmail.text'} />
                      </p>
                    </div>
                  </label>
                </div>
                {hidePersonalEmailCheckboxRef.current &&
                  hidePersonalEmailCheckboxRef.current.checked && (
                    <button
                      className={'btn btn2'}
                      onClick={handleDeleteEmailsFromLeads}
                      style={{ color: 'darkred' }}
                    >
                      <FormattedMessage id={'organization.deletePersonalEmails'} />
                    </button>
                  )}
              </div>
            </div>
          </div>
          <div className={'info-card'}>
            <div className="account-type-listing">
              {/*<div className="choose-heading-ui">
                <label>
                  <FormattedMessage id={"sidenav.organization"} />
                </label>
              </div>*/}
              <div
                className="choose-type-listing"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {isAdmin ? ( //admin: edit +delete org
                  <div className="button-ui delete-account">
                    <a
                      href="#"
                      className="btn2 delete-btn"
                      onClick={() => setDeleteTeamModal(true)}
                    >
                      <span className="ic-btn">
                        <img src="/assets/images/ic_delete_g.png" alt="" />
                      </span>{' '}
                      {/* <FormattedMessage id={"delete"} />*/}
                      <FormattedMessage id={'organization.deleteOrganization'} />
                    </a>
                  </div>
                ) : (
                  //member: leave org
                  <div className="button-ui delete-account">
                    <a
                      href="#"
                      className="btn2 edit-btn"
                      onClick={() => setDeleteMemberPopup(true)}
                    >
                      <span className="ic-btn">
                        <img src="/assets/images/ic_logout.svg" alt="" />
                      </span>
                      <FormattedMessage id={'organization.leaveTeam'} />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">{error + ' '}</div>
      )}

      {deleteEmailsFromLeadsModal && (
        <DeleteLeadsEmails
          setDeleteTeamModal={() => {
            setDeleteEmailsFromLeadsModal(false);
          }}
          setConfirm={() => setDeleteEmailsFromLeadsModal(false)}
        />
      )}
      {deleteTeamToggle && <DeleteOrganizationModal setDeleteTeamModal={setDeleteTeamModal} />}
      {deleteMemberPopUp && (
        <DeleteMember
          isLeaveOrganization={!isAdmin}
          toggle={deleteMemberPopUp}
          setToggle={setDeleteMemberPopup}
          member={{}}
        />
      )}
    </div>
  );
}
function DeleteLeadsEmails({ setDeleteTeamModal }) {
  const [textToDeleteOrg, setText] = useState(false);
  const [error, setError] = useState(false);
  const intl = useIntl();
  const handleSubmit = async () => {
    if (textToDeleteOrg === intl.formatMessage({ id: 'organization.deletePersonalEmails' })) {
      const { data, statusCode } = await deletePersonalEmails();
      if (statusCode === 200) {
        window.location = '/workspace';
      } else {
        setError(data.messageTranslated || data.message);
      }
    } else {
      setError(intl.formatMessage({ id: 'organization.pleaseEnterTheValidPhrase' }));
    }
  };
  return (
    <Modal
      show={true}
      onHide={() => setDeleteTeamModal(false)}
      className="fade  delete-organization-wrapper"
      id="delete-organization"
    >
      <Modal.Header closeButton className="border-0">
        <button type="button" className="close" data-dismiss="modal">
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id={'organization.deletePersonalEmails'} />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-24">
        <div className="delete-inner-ui">
          <div className="alert-wrap">
            <span className="ic-alert">
              <img src="/assets/images/ic-alert.svg" alt="" />
            </span>
            <label>
              {' '}
              <FormattedMessage id={'organization.yourAboutToDeletePersonnalEmails'} />{' '}
            </label>
            <p>
              <FormattedMessage
                id={'organization.onceEmailsDeleted'}
                values={{
                  span: (chunk) => <span>{chunk}</span>,
                  br: <br />,
                }}
              />
            </p>
          </div>

          <p>
            <FormattedMessage
              id={'organization.beforeThisAction'}
              values={{
                span: (chunk) => <span>{chunk}</span>,
                br: <br />,
              }}
            />
          </p>
          <div className="form-fields">
            <a href="#" className="delete-tag">
              “<FormattedMessage id={'organization.deletePersonalEmails'} />”
            </a>
            <div className="form-group">
              <FormattedMessage id={'organization.typeHere'}>
                {(placeHolder) => (
                  <input
                    type="text"
                    name=""
                    placeholder={placeHolder}
                    onChange={(e) => setText(e.target.value)}
                    style={
                      error ? { border: '1px solid red', color: '#981830' } : { color: '#981830' }
                    }
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">{error + ' '}</div>
      )}
      <div className="modal-footer border-0">
        <a href="#" className="btn6" onClick={() => setDeleteTeamModal(false)}>
          <FormattedMessage id={'cancel'} />
        </a>
        <a href="#" className="btn5 ml-20" onClick={handleSubmit}>
          <FormattedMessage id={'delete'} />
        </a>
      </div>
    </Modal>
  );
}

function DeleteOrganizationModal({ setDeleteTeamModal }) {
  const [textToDeleteOrg, setText] = useState(false);
  const [error, setError] = useState(false);
  const intl = useIntl();
  const handleSubmit = async () => {
    if (textToDeleteOrg === intl.formatMessage({ id: 'organization.deleteThisOrganization' })) {
      const { data, statusCode } = await deleteOrganization();
      if (statusCode === 200) {
        window.location = '/workspace/createjoin';
      } else {
        setError(data.messageTranslated || data.message);
      }
    } else {
      setError(intl.formatMessage({ id: 'organization.pleaseEnterTheValidPhrase' }));
    }
  };
  return (
    <Modal
      show={true}
      onHide={() => setDeleteTeamModal(false)}
      className="fade  delete-organization-wrapper"
      id="delete-organization"
    >
      <Modal.Header closeButton className="border-0">
        <button type="button" className="close" data-dismiss="modal">
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id={'organization.deleteOrganization'} />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-24">
        <div className="delete-inner-ui">
          <div className="alert-wrap">
            <span className="ic-alert">
              <img src="/assets/images/ic-alert.svg" alt="" />
            </span>
            <label>
              {' '}
              <FormattedMessage id={'organization.yourAboutToDeleteThisOrg'} />{' '}
            </label>
            <p>
              <FormattedMessage
                id={'organization.onceOrgIsDeleted'}
                values={{
                  span: (chunk) => <span>{chunk}</span>,
                  br: <br />,
                }}
              />
            </p>
          </div>

          <p>
            <FormattedMessage
              id={'organization.thisActionCannotBeUndone'}
              values={{
                span: (chunk) => <span>{chunk}</span>,
                br: <br />,
              }}
            />
          </p>
          <div className="form-fields">
            <a href="#" className="delete-tag">
              “<FormattedMessage id={'organization.deleteThisOrganization'} />”
            </a>
            <div className="form-group">
              <FormattedMessage id={'organization.typeHere'}>
                {(placeHolder) => (
                  <input
                    type="text"
                    name=""
                    placeholder={placeHolder}
                    onChange={(e) => setText(e.target.value)}
                    style={
                      error ? { border: '1px solid red', color: '#981830' } : { color: '#981830' }
                    }
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="btn-grp text-center  error  kaspr-form-text-error">{error + ' '}</div>
      )}
      <div className="modal-footer border-0">
        <a href="#" className="btn6" onClick={() => setDeleteTeamModal(false)}>
          <FormattedMessage id={'organization.cancelKeepIt'} />
        </a>
        <a href="#" className="btn5 ml-20" onClick={handleSubmit}>
          <FormattedMessage id={'organization.yesDeleteThisOrganization'} />
        </a>
      </div>
    </Modal>
  );
}
