import React, { useContext, useEffect, useState } from 'react';
import { Field } from '../common/Field/Field';
import { Col, Container, Row } from 'react-bootstrap';
import { resetPassword, resetPasswordRequest } from '../../../services/api/auth.api';
import queryString from 'query-string';
import { AuthContext } from '../Store/auth.store';
import actions from '../Store/auth.actions';
import { FormattedMessage, injectIntl } from 'react-intl';

function ForgotPassword({ intl }) {
  const [dataForm, setDataform] = useState({ email: '' });
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);

  const handleCheckEmail = async (e) => {
    e.preventDefault();
    const { data, statusCode } = await resetPasswordRequest({
      email: dataForm.email,
    });
    if (statusCode === 200) {
      setMessage(intl.formatMessage({ id: 'auth.emailHasBeenSent' }));
    } else {
      setError(data.messageTranslated || data.message);
    }
  };
  const handleOnchange = (data) => {
    setDataform({ ...dataForm, ...data });
  };
  return (
    <ResetForm
      handleCheckEmail={handleCheckEmail}
      onChange={handleOnchange}
      isEmailConfirm={true}
      message={message}
      error={error}
    />
  );
}

function ResetPassword({ intl }) {
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState(false);
  useEffect(() => {
    let { email, token } = queryString.parse(window.location.search);
    if (email && token) {
      dispatch({ type: actions.RESET_PASSWORD, payload: { token, email } });
    }
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (state.resetForm.password === state.resetForm.confirmPassword) {
      const { data, statusCode } = await resetPassword(state.resetForm);
      if (statusCode === 200) {
        window.location = '/signin?redirect=1';
      } else {
        setError(data.messageTranslated || data.message);
      }
    } else {
      setError(intl.formatMessage({ id: 'auth.pleaseMakeSureYourPasswordsMatch' }));
    }
  };
  return (
    <ResetForm
      handleOnSubmit={handleOnSubmit}
      dispatch={dispatch}
      state={state}
      email={state.resetForm.email}
      emailDisabled={true}
      error={error}
      intl={intl}
    />
  );
}

function ResetForm({
  isEmailConfirm,
  handleCheckEmail,
  handleOnSubmit,
  onChange,
  email,
  emailDisabled,
  dispatch,
  error,
  message,
  intl,
}) {
  return (
    <>
      <FormWrapper isEmailConfirm={isEmailConfirm} message={message}>
        {message ? (
          <div>
            <p style={{ color: 'green', textAlign: 'center', fontSize: 20 }}>{message}</p>
          </div>
        ) : (
          <form onSubmit={isEmailConfirm ? handleCheckEmail : handleOnSubmit}>
            <div className="form-blocks">
              <Field
                label={intl.formatMessage({ id: 'info.email' })}
                className={'active'}
                onChange={(email) => onChange({ email })}
                formType={'signin'}
                value={email}
                disabled={emailDisabled}
              />
              {!isEmailConfirm && (
                <>
                  <Field
                    label={intl.formatMessage({ id: 'info.password' })}
                    onChange={(password) =>
                      dispatch({
                        type: actions.RESET_PASSWORD,
                        payload: { password },
                      })
                    }
                    type={'password'}
                    //formType={"signin"}
                  />
                  <Field
                    label={intl.formatMessage({ id: 'info.confirmPassword' })}
                    onChange={(confirmPassword) =>
                      dispatch({
                        type: actions.RESET_PASSWORD,
                        payload: { confirmPassword },
                      })
                    }
                    type={'password'}
                    formType={'signin'}
                  />
                </>
              )}
            </div>
            {error && (
              <div
                className="btn-grp text-center  error  kaspr-form-text-error"
                style={{ top: -10, width: '90%' }}
              >
                {error + ' '}
              </div>
            )}
            {isEmailConfirm ? (
              <div className="btn-grp text-center">
                <button className="btn btn-blue btn-block h-58" type={'submit'}>
                  <FormattedMessage id={'auth.proceed'} />
                </button>
              </div>
            ) : (
              <div className="btn-grp text-center">
                <button className="btn btn-blue btn-block h-58" type={'submit'}>
                  <FormattedMessage id={'auth.letsGo'} />
                </button>
              </div>
            )}
          </form>
        )}
      </FormWrapper>
      <Footer />
    </>
  );
}
ResetForm = injectIntl(ResetForm);
function Footer() {
  return (
    <footer>
      <div className="container">
        <ul className="footer-list">
          <li>©2023 Kaspr</li>
          <li>·</li>
          <li>
            <p>
              <a target="_blank" href="https://www.kaspr.io/privacy-policy">
                <FormattedMessage id={'auth.privacy'} />
              </a>{' '}
              &amp;{' '}
              <a target="_blank" href="https://kaspr.io/terms/">
                <FormattedMessage id={'auth.terms'} />
              </a>
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
}

function FormWrapper({ children, isEmailConfirm, message }) {
  return (
    <section className="signup-wrapper">
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <div
              className="white-bg padding40 border-radius-12 mx-auto mt-5"
              style={{ maxWidth: '450px' }}
            >
              <div className="form-heading-grp text-center">
                {isEmailConfirm ? (
                  <h4 className="h4">
                    <FormattedMessage id={'auth.forgotPassword'} />
                  </h4>
                ) : (
                  <h4 className="h4">
                    <FormattedMessage id={'auth.resetPassword'} />
                  </h4>
                )}
                {isEmailConfirm && (
                  <p>
                    <FormattedMessage id={'auth.enterYourEmail'} />
                  </p>
                )}
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

ResetPassword = injectIntl(ResetPassword);
ForgotPassword = injectIntl(ForgotPassword);
export { ResetPassword, ForgotPassword, ResetForm };
