import React, { useEffect, useState } from 'react';
import AddEditMemberPopUp from '../AddEditMemberPopUp/AddEditMemberPopUp';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '../../../../components';
import './AddMemberNav.css';
import { getCredits } from '../../../../services/api/credits.api';
import { usePermissions } from '../../../../components/Permissions/permissions.hooks';
import querystring from 'query-string';
export default function AddMemberNav({
  organization,
  isAdmin,
  updateInvitations,
  resetRemaining,
  onMemberSearch,
  goToPage,
  memberFilters,
  remainingCredits,
  invitationsNumber,
  checkPlanPermissions,
  subscription,
  isVersion2,
  organizationCredits,
}) {
  const [addMemberToggle, setAddMemberModal] = useState(false);
  const [numberOfLicenses, setNumberOfLicenses] = useState(0);
  useEffect(() => {
    getCredits().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        setNumberOfLicenses(
          data.organizationCredits &&
            data.organizationCredits.plan &&
            data.organizationCredits.plan.numberOfSeats,
        );
      }
    });
    const { addMembers } = querystring.parse(window.location.search);
    if (addMembers == 'true') {
      setAddMemberModal(true);
      window.history.pushState(null, null, '/workspace');
    }
  }, []);
  return (
    <div className="head_block" style={{ background: 'url(/assets/images/mainbg.jpg)' }}>
      <div className="left_block total-members">
        <div className="title-box">
          <p>
            {organization && organization.totalMembers}{' '}
            <FormattedMessage id={'organization.member'} />
            {organization.totalMembers !== 1 && 's'}{' '}
          </p>
        </div>
        <div
          className={'page-header'}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="search-ui " style={{ width: '300px' }}>
            <FormattedMessage id={'leads.searchInTheList'}>
              {(placeholder) => (
                <input
                  type="text"
                  name="search"
                  placeholder={placeholder}
                  autoComplete={'off'}
                  style={{ width: '100%' }}
                  onChange={(e) => onMemberSearch({ search: e.target.value })}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>

      <div className="right_block">
        <div style={{ marginLeft: 20 }}>
          <Pagination
            pages={organization.membersPages}
            currentPage={memberFilters.page}
            goToPage={goToPage}
            top
          />
        </div>
        {isAdmin && invitationsNumber + organization.totalMembers >= numberOfLicenses ? (
          <a
            href="#"
            className="btn1 addmember-btn"
            onClick={() => {
              window.location = '/billing/info?addLicense=true';
            }}
          >
            <span className="icon">
              <img src="/assets/images/ic-add-member.png" alt="" />
            </span>
            <FormattedMessage id={'organization.addLicense'} />
          </a>
        ) : (
          <a href="#" className="btn1 addmember-btn" onClick={() => setAddMemberModal(true)}>
            <span className="icon">
              <img src="/assets/images/ic-add-member.png" alt="" />
            </span>
            <FormattedMessage id={'organization.addMember'} />
          </a>
        )}
      </div>
      {addMemberToggle && (
        <AddEditMemberPopUp
          setAddMemberModal={setAddMemberModal}
          organization={organization}
          updateInvitations={updateInvitations}
          resetRemaining={resetRemaining}
          remainingCredits={remainingCredits}
          remainingInviations={Math.max(
            numberOfLicenses - invitationsNumber - organization.totalMembers,
            0,
          )}
          isAdd
          isAdmin={isAdmin}
          checkPlanPermissions={checkPlanPermissions}
          isVersion2={isVersion2}
          subscription={subscription}
          organizationCredits={organizationCredits}
        />
      )}
    </div>
  );
}
