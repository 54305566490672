import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import StarRating from '../../common/StarRating/StarRating';
import { CHROME_STORE } from '../../../../constants';

const RedirectionPage = ({ isExtensionInstalled, handleOnClick }) => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (countdown === 0) {
      const redirectUrl = isExtensionInstalled
        ? 'https://www.linkedin.com/in/anne-charlotte-lazou/?synchro=kaspr&isFirstKasprSynchro=true'
        : CHROME_STORE;
      window.location.href = redirectUrl;
    }

    const timer = countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
    return () => clearInterval(timer);
  }, [countdown, isExtensionInstalled]);

  return (
    <div className={'container'}>
      <div className="container-top">
        <h1 className={'onboarding-title'}>
          {isExtensionInstalled ? (
            <FormattedMessage id="auth.takingYouBackToLinkedin" />
          ) : (
            <FormattedMessage id="auth.timeToInstallOurExtension" />
          )}
        </h1>

        <div className="onboarding-desc">
          <p>
            {isExtensionInstalled ? (
              <FormattedMessage id="auth.extensionInstalledDesc" />
            ) : (
              <FormattedMessage id="auth.extensionNotInstalledDesc" />
            )}
          </p>
        </div>
      </div>

      <button
        onClick={() => handleOnClick(isExtensionInstalled ? 'plugin' : 'linkedin_connect')}
        className="btn btn1 redirection-btn"
      >
        <FormattedMessage id="auth.redirectingYouIn" /> {countdown}
      </button>
      <div className="rating-ctn">
        <StarRating rating={4.4} />
        <div className="rating">
          <p>
            <FormattedMessage id="auth.ratingOn" values={{ value: '4,4' }} />
          </p>
          <a
            href="https://www.g2.com/products/kaspr/reviews"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/g2_logo.png" width={20} alt="G2 logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RedirectionPage;
