import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AuthContext, actions } from '../../Store/auth.store';
import {
  phoneVerificationRequest,
  resendCode,
  validatePhone,
} from '../../../../services/api/auth.api';
import { PhoneField as PhoneFieldInput } from '../../common/Field/Field';
import { FormattedMessage, injectIntl } from 'react-intl';
import trackingEvents from '../../../../services/TrackingEvents/TrackingEvents';

function ConfirmPhonePopUp({
  toggle,
  setConfirmPopupToggle,
  onPhoneChange,
  phone,
  onClick,
  error,
  tracking = {},
}) {
  return (
    <Modal show={toggle} onHide={() => setConfirmPopupToggle(false)}>
      <Modal.Header closeButton className="border-0">
        <div className="modal-inner-header">
          <h4 className="title">
            {' '}
            <FormattedMessage id={'auth.verifyYourPhoneNumber'} />
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="inner-content enter-phone-number">
          <div className="form-group">
            <label>
              <FormattedMessage id={'auth.enterYourPhoneNumber'} />{' '}
            </label>
          </div>
          <PhoneFieldInput
            onChange={(countryCode, phoneNumber, isValidPhone) =>
              onPhoneChange({ phoneNumber, countryCode })
            }
            propsValue={phone ? phone.countryCode + '' + phone.phoneNumber : ''}
          />
          <p className="info-ui" style={{ marginTop: 10, paddingTop: 6 }}>
            <FormattedMessage id={'auth.byTappingProceed'} />
          </p>
        </div>
        {error && (
          <div
            className="text-center  error  kaspr-form-text-error"
            style={{ top: 10, width: '90%' }}
          >
            {error + ' '}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="border-0">
        <a
          href="#"
          className="btn4 btn-md mr14"
          onClick={() => setConfirmPopupToggle(false)}
          data-tracking-id={tracking.cancel}
        >
          <FormattedMessage id={'cancel'} />
        </a>
        <a
          href="#"
          className="btn1 btn-md"
          onClick={(e) => {
            e.preventDefault();
            onClick('confirm_phone');
          }}
          data-tracking-id={tracking.proceed}
        >
          <FormattedMessage id={'auth.proceed'} />
        </a>
      </Modal.Footer>
    </Modal>
  );
}

function PhoneValidation({
  handleCloseModal,
  toggle,
  profile,
  phone,
  onClick,
  showToastMessage,
  resetCredits,
  intl,
  tracking = {},
}) {
  const [error, setError] = useState(null);
  const [code, setCode] = useState(null);
  const [counter, setCounter] = React.useState(0);
  const [showCountdown, setShowCountdown] = useState(false);
  const inputs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
  };
  const forceUpdate = useState()[1].bind(null, {});
  const countdown = async () => {
    setCounter(60);
    forceUpdate();
  };
  useEffect(() => {
    if (inputs['1'].current) {
      inputs['1'].current.focus();
    }
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [toggle, counter]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (code && code.length == 4) {
      const { data, statusCode } = await validatePhone({
        token: code,
      });
      if (statusCode >= 400) setError(data.messageTranslated || data.message);
      else {
        if (data && data.success) window.location.reload();
      }
    }
  };

  const handleKeyUp = (e) => {
    const value = e.target.value;
    let nextId = 1;
    if (
      (!isNaN(value) && value >= 0 && value <= 9 && e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (value) {
        nextId = 1;
        let next = parseInt(e.target.id) + nextId;
        if (next <= 4) {
          if (inputs[next].current) {
            inputs[next].current.value = '';
            inputs[next].current.focus();
          }
        }
      }
    } else if (e.keyCode == 8) {
      nextId = 1;
      let previous = parseInt(e.target.id) - nextId;
      if (previous >= 1) {
        if (inputs[previous].current) {
          inputs[previous].current.focus();
        }
      }
    }
    const allInputs = Object.keys(inputs).map((key) => inputs[key].current.value);
    setCode(allInputs.join(''));
  };

  const handleResendCode = async () => {
    const { data, statusCode } = await phoneVerificationRequest();
    if (statusCode === 200) {
      showToastMessage('success');
      await countdown();
    } else {
      showToastMessage(
        intl.formatMessage({ id: 'auth.anErrorHasOccurredWhileResending' }) + data.message,
      );
    }
  };
  return (
    <PhoneValidationWrapper
      handleCloseModal={handleCloseModal}
      toggle={toggle}
      handleOnSubmit={handleOnSubmit}
      profile={profile}
      phone={phone}
      onClick={onClick}
      tracking={tracking}
    >
      <form>
        <div className="enter-code-field">
          <PhoneField ref={inputs['1']} id={'1'} onKeyUp={handleKeyUp} />
          <PhoneField ref={inputs['2']} id={'2'} onKeyUp={handleKeyUp} />
          <PhoneField ref={inputs['3']} id={'3'} onKeyUp={handleKeyUp} />
          <PhoneField ref={inputs['4']} id={'4'} onKeyUp={handleKeyUp} />
        </div>
        <p className="info-text">
          <p className="info-text">
            <FormattedMessage id={'auth.didntReceiveIt'} />
            {counter > 0 ? (
              <a className="text-btn" data-tracking-id={tracking.resendTryAgain}>
                <FormattedMessage id={'auth.tryAgainIn'} /> {counter}{' '}
                <FormattedMessage id={'auth.seconds'} />
              </a>
            ) : (
              <a
                href="#"
                className="text-btn"
                onClick={handleResendCode}
                data-tracking-id={tracking.resend}
              >
                <FormattedMessage id={'auth.resendCode'} />
              </a>
            )}
          </p>
          {/*Didn’t received?{" "}*/}
          {/* <a href="#" className="text-btn">
            Resend
          </a>*/}
        </p>
        {error && (
          <div
            className="btn-grp text-center  error  kaspr-form-text-error"
            style={{ top: 10, width: '90%' }}
          >
            {error + ' '}
          </div>
        )}
      </form>
    </PhoneValidationWrapper>
  );
}

const PhoneField = React.forwardRef(({ id, onKeyUp }, ref) => {
  const handleKeyUp = (event) => {
    onKeyUp(event, id);
  };
  return (
    <input
      type="text"
      maxLength="1"
      size="1"
      min="0"
      max="9"
      pattern="[0-9]{1}"
      ref={ref}
      onKeyUp={handleKeyUp}
      id={id}
    />
  );
});

function PhoneValidationWrapper({
  children,
  handleCloseModal,
  toggle,
  handleOnSubmit,
  phone,
  onClick,
  tracking,
}) {
  return (
    <Modal show={toggle} onHide={handleCloseModal}>
      <Modal.Header closeButton onHide={handleCloseModal} className="modal-header border-0">
        <div className="modal-inner-header">
          <h4 className="title">
            {' '}
            <FormattedMessage id={'auth.checkYourPhone'} />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-0">
        <div className="inner-content phone-verifiy-ui">
          <div className="phone-info">
            <p>
              {' '}
              <FormattedMessage id={'auth.youllReceiveA4Digit'} />
            </p>
            <span className="number-ui">{phone.countryCode + '' + phone.phoneNumber}</span>
            <a
              href="#"
              className="text-btn"
              onClick={() => onClick('edit_phone')}
              data-tracking-id={tracking.editPhone}
            >
              <FormattedMessage id={'edit'} />
            </a>
          </div>
          <div className="code-ui">
            <label>
              {' '}
              <FormattedMessage id={'auth.enter4Digit'} />
            </label>
            {children}
          </div>
        </div>
      </div>
      <div className="modal-footer border-0">
        <a
          href="#"
          className="btn4 btn-md mr14"
          onClick={handleCloseModal}
          data-tracking-id={tracking.cancel}
        >
          <FormattedMessage id={'cancel'} />
        </a>
        <a
          href="#"
          className="btn1 btn-md"
          onClick={handleOnSubmit}
          data-tracking-id={tracking.verify}
        >
          <FormattedMessage id={'settings.profile.verify'} />
        </a>
      </div>
    </Modal>
  );
}
ConfirmPhonePopUp = injectIntl(ConfirmPhonePopUp);
PhoneValidation = injectIntl(PhoneValidation);

export { ConfirmPhonePopUp, PhoneValidation };
