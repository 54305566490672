import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { actions, AuthContext } from '../Store/auth.store';
import { resendCode, validateEmail } from '../../../services/api/auth.api';
import { Redirect } from 'react-router-dom';
import CodeVerification from 'react-verification-code-input';
import queryString from 'query-string';
import { IS_PROD } from '../../../constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import './ConfirmAccount.css';
function ConfirmAccount(props) {
  const { state, dispatch } = useContext(AuthContext);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(false);

  const countdown = async () => {
    setCounter(60);
  };
  useEffect(() => {
    let { token } = queryString.parse(window.location.search);
    if (token) {
      validateEmail(token).then(({ data, statusCode }) => {
        if (data.success) {
          window.location = '/welcome';
        }
      });
    }
  }, []);
  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleOnSubmit = async (e) => {
    let codeConfirmAccount = state.codeConfirmAccount;
    if (e.preventDefault) e.preventDefault();
    else codeConfirmAccount = e;

    if (codeConfirmAccount && codeConfirmAccount.length === 6) {
      setLoading(true);
      const { data, statusCode } = await validateEmail(codeConfirmAccount);
      setLoading(false);
      if (statusCode !== 200) {
        props.showToastMessage(
          props.intl.formatMessage({
            id: 'auth.anErrorHasOccurredWhileValidating',
          }) + data.message,
        );
      }
      if (data.success) {
        if (IS_PROD) {
          window.googleTagManager('registration');
        }
        window.location = '/welcome';
      }
    }
  };

  const handleResendCode = async () => {
    const { data, statusCode } = await resendCode();
    if (statusCode === 200) {
      props.showToastMessage('success');
      await countdown();
    } else {
      props.showToastMessage(
        props.intl.formatMessage({
          id: 'auth.anErrorHasOccurredWhileResending',
        }) + data.message,
      );
    }
  };

  const handleOnChange = (code) => {
    if (code.length == 6) setActiveButton(true);
    else setActiveButton(false);
    dispatch({ type: actions.ACCOUNT_VALIDATION, payload: code });
  };
  return (
    <ConfirmAccountWrapper profile={props.profile}>
      <div className="code-ui">
        <label>
          <FormattedMessage id={'auth.enter6Digit'} />{' '}
        </label>
        <form>
          <div className="enter-code-field mb-46">
            <CodeVerification
              // onComplete={handleOnSubmit}
              onChange={handleOnChange}
              type={'number'}
            />
          </div>
          <p className="info-text">
            <FormattedMessage id={'auth.didntReceiveIt'} />
            {counter > 0 ? (
              <a className="text-btn">
                {' '}
                <FormattedMessage id={'auth.tryAgainInSeconds'} values={{ value: counter }} />
              </a>
            ) : (
              <a href="#" className="text-btn" onClick={handleResendCode}>
                <FormattedMessage id={'auth.resendCode'} />
              </a>
            )}
          </p>
          <a
            href="#"
            className="btn1 btn-56 mt-24"
            onClick={(e) => (activeButton ? handleOnSubmit(e) : () => false)}
            style={{
              fontSize: 20,
              ...(activeButton ? {} : { background: '#ccc', textShadow: 'none' }),
            }}
          >
            {loading ? (
              <img style={{ width: 30 }} src="/assets/images/loading.gif" alt="" />
            ) : (
              <FormattedMessage id={'auth.next'} />
            )}
          </a>
        </form>
      </div>
    </ConfirmAccountWrapper>
  );
}

function ConfirmAccountLeft() {
  return (
    <Col lg={3} className="d-none d-xl-block">
      <div className="left-ui">
        <h4>
          {' '}
          <FormattedMessage id={'auth.letsGetStarted'} />
        </h4>
        <p>
          <FormattedMessage id={'auth.kasprIsAGreatTool'} />
        </p>

        <div className="steps-block email-verify-listing">
          <label className="sub-title">
            {' '}
            <FormattedMessage id={'auth.whatYouGetWithKaspr'} />
          </label>
          <div className="d-flex fade-in-txt one">
            <p>
              <FormattedMessage id={'auth.upTo40'} />
            </p>
          </div>
          <div className="d-flex fade-in-txt two">
            <p>
              <FormattedMessage id={'auth.industryLeading'} />
            </p>
          </div>
          <div className="d-flex fade-in-txt three">
            <p>
              <FormattedMessage id={'auth.trustedByTopBusinesses'} />
            </p>
          </div>
        </div>

        <div className="need-help-ui">
          <a href="https://kaspr.io/contact/" target={'_blank'} className="text-btn">
            <FormattedMessage id={'auth.needHelp'} />
          </a>
        </div>
      </div>
    </Col>
  );
}
function ConfirmAccountWrapper({ children, profile }) {
  return (
    <div className="main-wraper onboarding-wrapper">
      <div className="onboard-content-ui" style={{ height: '100vh' }}>
        <div className="container-fluid">
          <Row>
            <ConfirmAccountLeft />

            <div className="col-lg-9">
              <div className="onboard-content">
                <h3 className="title">
                  <span>
                    <FormattedMessage
                      id={'auth.hey'}
                      values={{
                        value: (
                          <span style={{ textTransform: 'capitalize' }}>{profile.firstName}</span>
                        ),
                      }}
                    />{' '}
                  </span>
                  <br />
                  <FormattedMessage id={'auth.validateYourEmail'} />
                </h3>
                <p>
                  <FormattedMessage
                    id={'auth.weVeSentYouAConfirmationCode'}
                    values={{ value: <span>{profile.email}</span> }}
                  />
                  <br />
                  <FormattedMessage id={'auth.pleaseCheckYourInbox'} />
                </p>
                {children}
              </div>
            </div>
          </Row>
          <div className="row onboard-footer">
            <div className="col-lg-3 align-self-center">
              <p className="copyright">
                ©2023 Kaspr ·{' '}
                <a target="_blank" href="https://www.kaspr.io/privacy-policy">
                  <FormattedMessage id={'auth.privacy'} />
                </a>{' '}
                &amp;{' '}
                <a target="_blank" href="https://kaspr.io/terms/">
                  <FormattedMessage id={'auth.terms'} />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(ConfirmAccount);
