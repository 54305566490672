import React, { useContext, useEffect, useRef, useState } from 'react';
import { LeadsContext, actions } from '../../store/leads.store';
import './LeadsNav.css';
import { leadsFilter } from '../../../../services/api/leads.api';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Animation, ModalMessage, Permissions, Tooltip } from '../../../../components';
import { Button, OverlayTrigger } from 'react-bootstrap';
import CreateNewListModal from '../CreateNewListModal/CreateNwListModal';
import { deleteLabel, getLabels, editLabel } from '../../../../services/api/labels.api';
import { checkIsPlanLimited } from '../../../../services/utils/checkPermissions';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { getMembers } from '../../../../services/api/organization.api';
import { NavLink } from 'react-router-dom';
import { debounce } from '../../../../services/utils/tools';

const LabelItem = ({ label, onLabelSelect, setListToDelete, lisToEdit, setListToEdit }) => {
  const handleLabelSelect = (e) => {
    if (label.id !== lisToEdit) onLabelSelect(label);
  };

  return (
    <li style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className="radio_btn_wrap "
        onClick={handleLabelSelect}
        style={{ width: '100%' }}
        data-tracking-id={'leads.filters-list-item'}
      >
        <label>
          <input type="radio" name="filter" value="All Leads" />
          <input type="radio" name="filter" value={<FormattedMessage id={'leads.allLeads'} />} />
          <span className="radio-icon"></span>
          <div>
            <span className="label_text">{label.name}</span>
          </div>
        </label>
      </div>
      <span
        onClick={(e) => setListToEdit(e, label)}
        style={{ cursor: 'pointer', marginRight: '7px' }}
      >
        <img src="/assets/images/ic_edit_g.png" alt="" width={14} />
      </span>
      <span onClick={() => setListToDelete(label)} style={{ cursor: 'pointer', right: '0' }}>
        <img src="/assets/images/icon-delete.svg" alt="" />
      </span>
    </li>
  );
};

const MemberItem = ({ member, onMemberSelect }) => {
  return (
    <li onClick={() => onMemberSelect(member)}>
      <a
        href="#"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        data-tracking-id={'leads-filter-addedBy-item'}
      >
        <span className="name_tag light_red" style={{ textTransform: 'uppercase' }}>
          {(member.firstName ? member.firstName[0] : '') +
            (member.lastName ? member.lastName[0] : '')}
        </span>

        <span className="name_ui"> {member.firstName + ' ' + member.lastName}</span>
      </a>
    </li>
  );
};

function LeadsNav({ organizationCredit, setPage }) {
  const { state, dispatch } = useContext(LeadsContext);
  const [date, setDate] = useState(null);
  const [dateToggle, setDateToggle] = useState(false);
  const [selectToggle, setSelectToggle] = useState(false);
  const [createLabelToggle, setCreateLabelToggle] = useState(false);
  const [editLabelToggle, setEditLabelToggle] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);
  const [listToEdit, setListToEdit] = useState(null);
  const [addedBySearch, setAddedBySearch] = useState('');
  const [isCreateListAllowed, setIsCreateListAllowed] = useState(true);
  const intl = useIntl();
  useEffect(() => {
    if (organizationCredit.plan.maxLists !== -1) {
      setIsCreateListAllowed(
        state.labels && state.labels.length <= organizationCredit.plan.maxLists,
      );
    }
  }, [state.labels]);
  const handleSetListToEdit = (e, label) => {
    if (e) e.stopPropagation();
    setListToEdit(label);
    setEditLabelToggle(true);
  };

  const handleFilter = debounce(async ({ member, label, search = '' }) => {
    const leadsData = state.dataForm;
    leadsData.search = search;
    if (member) {
      leadsData.addedBy = member.email;
    } else {
      leadsData.addedBy = '';
    }
    if (label) {
      leadsData.labelId = label.id;
      dispatch({ type: actions.SELECT_LABEL, payload: label });
    } else {
      leadsData.labelId = state.dataForm.labelId;
    }

    dispatch({
      type: actions.LEADS_FILTER,
      payload: { ...leadsData, page: 1 },
    });
    if (setPage) setPage(1);
    setTriggerFilter(!triggerFilter);
  });
  const handleDatePickerToggle = () => {
    setDateToggle(!dateToggle);
  };
  const handleFilterByDate = (date) => {
    setDate(date);
    const formatedDate = new Date(date);
    const leadsData = { ...state.dataForm, date: formatedDate };
    dispatch({
      type: actions.LEADS_FILTER,
      payload: { ...leadsData, page: 1 },
    });
    dispatch({
      type: actions.LEADS_LOADING,
      payload: true,
    });
    leadsFilter({ data: leadsData }).then(({ data }) => {
      dispatch({
        type: actions.GET_LEADS,
        payload: data.leads,
        pages: data.pages,
      });
      dispatch({
        type: actions.LEADS_LOADING,
        payload: false,
      });
    });
  };
  const handleLabelDelete = async () => {
    setListToDelete(false);
    const { data, statusCode } = await deleteLabel(listToDelete.id);
    if (statusCode == 200) {
      getLabels().then((data) => {
        dispatch({ type: actions.GET_LABELS, payload: data.labels });
      });
    }
  };
  useEffect(() => {
    dispatch({
      type: actions.LEADS_LOADING,
      payload: true,
    });
    leadsFilter({ data: state.dataForm }).then(({ data }) => {
      dispatch({
        type: actions.GET_LEADS,
        payload: data.leads,
        pages: data.pages,
      });
      dispatch({
        type: actions.LEADS_LOADING,
        payload: false,
      });
    });
  }, [triggerFilter]);

  const addedByFilter = async ({ search }) => {
    setAddedBySearch(search);
    const { data, statusCode } = await getMembers({ search });
    if (statusCode == 200) {
      dispatch({
        type: actions.GET_MEMBERS,
        payload: data.members,
      });
      dispatch({
        type: actions.LEADS_FILTER,
        payload: { ...state.dataForm, page: 1 },
      });
      if (setPage) setPage(1);
    }
  };
  return (
    <section className="leads-nav-container">
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 align-self-center">
            <div className="cstm-dropdown lead_filter">
              <div className="dropdown">
                <Button
                  variant={'primary'}
                  className="dropdown-toggle"
                  onClick={() => setSelectToggle('labels')}
                  disabled={checkIsPlanLimited({
                    permissions: organizationCredit && organizationCredit.plan.permissions,
                    name: 'Leads',
                  })}
                  data-tracking-id={'leads.filters-list'}
                >
                  {state.selectedLabel.name}
                  {checkIsPlanLimited({
                    permissions: organizationCredit && organizationCredit.plan.permissions,
                    name: 'Leads',
                  }) && (
                    <OverlayTrigger
                      key={'plan-permission'}
                      placement={'top'}
                      overlay={(props) =>
                        Tooltip({
                          ...props,
                          data: <FormattedMessage id={'permissions.upgradePlan'} />,
                        })
                      }
                    >
                      <img
                        src="assets/images/lockBlack.png"
                        style={{ width: 15, marginLeft: 7 }}
                        alt=""
                      />
                    </OverlayTrigger>
                  )}
                </Button>
                <Animation.Appear
                  className={'dropdown-menu show'}
                  toggle={selectToggle === 'labels' ? selectToggle : false}
                  setToggle={setSelectToggle}
                  element={
                    !isCreateListAllowed ? (
                      <Permissions.CallToAction withoutIcon={true} isPlan={true}>
                        <a
                          href="#"
                          className="cnl_btn"
                          data-tracking-id={'leads.filters-list-createNewList'}
                        >
                          <span className="plus-icon">+</span>{' '}
                          <FormattedMessage id={'leads.createANewList'} />
                        </a>
                      </Permissions.CallToAction>
                    ) : (
                      <a
                        href="#"
                        className="cnl_btn"
                        onClick={() => {
                          setCreateLabelToggle(true);
                        }}
                        data-tracking-id={'leads.filters-list-createNewList'}
                      >
                        <span className="plus-icon">+</span>{' '}
                        <FormattedMessage id={'leads.createANewList'} />
                      </a>
                    )
                  }
                >
                  <ul className="dropdown-list">
                    <li
                      onClick={() =>
                        handleFilter({
                          label: {
                            name: <FormattedMessage id={'leads.allLeads'} />,
                            id: '',
                          },
                        })
                      }
                      data-tracking-id={'leads.filters-list-allLeads'}
                    >
                      <div className="radio_btn_wrap ">
                        <label>
                          <input
                            type="radio"
                            name="filter"
                            value={<FormattedMessage id={'leads.allLeads'} />}
                          />
                          <span className="radio-icon"></span>
                          <span className="label_text">
                            <FormattedMessage id={'leads.allLeads'} />
                          </span>
                        </label>
                      </div>
                    </li>
                    <li
                      onClick={() =>
                        handleFilter({
                          label: {
                            name: <FormattedMessage id={'leads.defaultList'} />,
                            id: null,
                          },
                        })
                      }
                      data-tracking-id={'leads.filters-list-allLeads'}
                    >
                      <div className="radio_btn_wrap ">
                        <label>
                          <input type="radio" name="filter" value="Default list" />
                          <span className="radio-icon"></span>
                          <span className="label_text">
                            <FormattedMessage id={'leads.defaultList'} />
                          </span>
                        </label>
                      </div>
                    </li>
                    {state.labels &&
                      state.labels.map((label, i) => {
                        return (
                          <LabelItem
                            key={i}
                            label={label}
                            onLabelSelect={(label) => handleFilter({ label })}
                            setListToDelete={setListToDelete}
                            setListToEdit={(e, label) => {
                              setEditLabelToggle(true);
                              handleSetListToEdit(e, label);
                            }}
                          />
                        );
                      })}
                  </ul>
                </Animation.Appear>
                {listToDelete && (
                  <ModalMessage
                    toggle={!!listToDelete}
                    setToggle={() => {
                      setListToDelete(false);
                    }}
                    title={intl.formatMessage({ id: 'delete' }) + ' ' + listToDelete.name}
                    textElement={
                      <>
                        <strong>
                          <FormattedMessage id={'leads.pleaseConfirmARemovalOf'} />{' '}
                          {listToDelete.name}
                        </strong>
                        <span style={{ color: '#666', display: 'block' }}>
                          <FormattedMessage id={'lead.deletedList'} />
                        </span>
                      </>
                    }
                    action={'Confirm'}
                    tracking={{
                      confirm: 'leads.deleteList-confirm',
                      cancel: 'leads.deleteList-cancel',
                    }}
                    handleAction={handleLabelDelete}
                  />
                )}
              </div>
            </div>
            <div className="cstm-dropdown addedby user_filter">
              <div className="dropdown">
                <Button
                  variant={'primary'}
                  className="dropdown-toggle"
                  onClick={() => setSelectToggle('addedby')}
                  disabled={checkIsPlanLimited({
                    permissions: organizationCredit && organizationCredit.plan.permissions,
                    name: 'Leads',
                  })}
                  style={{ minWidth: 'max-content' }}
                  data-tracking-id={'leads.filter-addedBy'}
                >
                  <FormattedMessage id={'leads.addedBy'} />
                </Button>
                <Animation.Appear
                  className={'dropdown-menu show'}
                  toggle={selectToggle === 'addedby' ? selectToggle : false}
                  setToggle={setSelectToggle}
                >
                  <div className={'search-input'}>
                    <i className="fas fa-search"></i>
                    <input
                      type="text"
                      style={{ border: 'none' }}
                      placeholder={intl.formatMessage({
                        id: 'leads.search',
                      })}
                      onChange={(e) => addedByFilter({ search: e.target.value })}
                      value={addedBySearch}
                    />
                  </div>
                  <ul>
                    <li
                      onClick={() => {
                        addedByFilter({ search: '' });
                        setSelectToggle(false);
                        handleFilter({ search: '' });
                      }}
                    >
                      <a href="#" data-tracking-id={'leads.filter-addedBy-all'}>
                        <span
                          className="name_tag light_red first-all"
                          style={{ textTransform: 'uppercase' }}
                        >
                          <FormattedMessage id={'leads.all'} />
                        </span>
                        <span className="name_ui" style={{ color: '#3b53d1' }}>
                          <FormattedMessage id={'organization.allMembers'} />
                        </span>
                      </a>
                      <hr />
                    </li>
                    {state.members && state.members.length > 0 ? (
                      state.members.map((member, i) => (
                        <MemberItem
                          key={i}
                          member={member}
                          onMemberSelect={(member) => {
                            handleFilter({ member });

                            setAddedBySearch(member.firstName + ' ' + member.lastName);
                            setSelectToggle(false);
                          }}
                        />
                      ))
                    ) : (
                      <li style={{ textAlign: 'center' }}>
                        <a href="#">
                          <span className="name_ui">
                            <FormattedMessage id={'workflow.noResultsFound'} />
                          </span>
                        </a>
                      </li>
                    )}
                  </ul>
                </Animation.Appear>
              </div>
            </div>
            {createLabelToggle && (
              <CreateNewListModal
                toggle={createLabelToggle}
                setToggle={setCreateLabelToggle}
                setCurrentToggle={setSelectToggle}
                tracking={{
                  action: 'leads.filters-list-createNewListPopUp-create',
                  cancel: 'leads.filters-list-createNewListPopUp-cancel',
                }}
              />
            )}
            {editLabelToggle && (
              <CreateNewListModal
                toggle={editLabelToggle}
                setToggle={setEditLabelToggle}
                setCurrentToggle={setSelectToggle}
                label={listToEdit}
                tracking={{
                  action: 'leads.filters-list-createNewListPopUp-edit',
                  cancel: 'leads.filters-list-createNewListPopUp-cancel',
                }}
              />
            )}
          </div>
          <div className="col-md-6 align-self-center text-right">
            <div className="search-ui" data-tracking-id={'leads.filters-searchInput'}>
              <FormattedMessage id={'leads.searchInTheList'}>
                {(placeholder) => (
                  <input
                    type="text"
                    name="search"
                    placeholder={placeholder}
                    onChange={(e) => handleFilter({ search: e.target.value })}
                    autoComplete={'off'}
                    style={
                      checkIsPlanLimited({
                        permissions: organizationCredit && organizationCredit.plan.permissions,
                        name: 'Leads',
                      })
                        ? { background: '#ccc' }
                        : {}
                    }
                    disabled={checkIsPlanLimited({
                      permissions: organizationCredit && organizationCredit.plan.permissions,
                      name: 'Leads',
                    })}
                  />
                )}
              </FormattedMessage>
            </div>
            {/*
            <div className="datepicker-ui ">
              <span className="datepicker-icon">
                {!checkIsPlanLimited({
                  permissions:
                    organizationCredit && organizationCredit.plan.permissions,
                  name: "Leads",
                }) ? (
                  <span
                    className="add-on dropdown-toggle"
                    onClick={handleDatePickerToggle}
                    data-tracking-id={"leads.filters-searchByDate"}
                  >
                    <img src="/assets/images//ic_calendar.png" alt="" />
                  </span>
                ) : (
                  <span>
                    <OverlayTrigger
                      key={"plan-permission"}
                      placement={"top"}
                      overlay={(props) =>
                        Tooltip({
                          ...props,
                          data: (
                            <FormattedMessage id={"permissions.upgradePlan"} />
                          ),
                        })
                      }
                    >
                      <img
                        src="assets/images/lockBlack.png"
                        style={{ width: 15, marginLeft: 7 }}
                        alt=""
                      />
                    </OverlayTrigger>
                  </span>
                )}
                {
                  <Animation.FadeIn
                    toggle={dateToggle}
                    setToggle={setDateToggle}
                  >
                    <div className={"date-picker-container"}>
                      <Calendar
                        onChange={handleFilterByDate}
                        value={state.dataForm.date}
                        className={"date-picker"}
                      />
                    </div>
                  </Animation.FadeIn>
                }
              </span>
            </div>
*/}
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeadsNav;
