import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  EnrichementTemplate,
  // IntegrationTemplate,
} from '../../../common/Templates/Templates';
import { Col, Form } from 'react-bootstrap';
import TemplateSettings from '../../../common/Templates/TemplateSettings';
import {
  createWorkflow,
  getTemplates,
  getWorkflow,
  getWorkflows,
} from '../../../../../services/api/workflows.api';
import { PreLoader } from '../../../../../components';
import { WorkflowContext } from '../../../store/workflow.store';
import { updateBlock } from '../../../../../services/api/workflows.api';
import actions from '../../../store/workflow.actions';
import { FormattedMessage } from 'react-intl';
import './CurrentWorkflow.css';
import allIntegration from '../../../common/allIntegrations.json';
import allIntegrations from '../../../common/allIntegrations.json';
import { getAccounts } from '../../../../../services/api/integrations.api';
import { useParams } from 'react-router-dom';
import MappingModal from '../../../../Settings/Integration/Modals/MappingModal/MappingModal';
import { checkPermission } from '../../../../../services/utils';
import { ToastContainer, toast } from 'react-toastify';
import { DASHBOARD_URL } from '../../../../../constants';

export default function CurrentWorkflow({
  workflow,
  navToggle,
  profile,
  organizationCredits,
  setWorkflowName,
  workflowName,
  hanldeChangeWokrflowName,
  setWorkflowToRemove,
}) {
  const [blockOptions, setBlockOptions] = useState(false);
  const [isBlockActive, setBlockActivation] = useState(false);
  const [selectedWorkflow, selectWorkflow] = useState(null);
  const nameRef = useRef(null);
  const { integration: integrationName } = useParams();
  const { state, dispatch } = useContext(WorkflowContext);
  const [integrations, setIntegrations] = useState([]);
  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
  const [isBlockAttached, setIsBlockAttached] = useState(false);
  const [currentWorkflowName, setCurrentWorkflowName] = useState(workflow.name);
  const [isWkAttached, setIsWkAttached] = useState(false);
  const [workflowId, setWorkflowId] = useState(null);

  const getBlock = (id) => {
    return state.selectedTemplate.blocks.find((block) => block.id == id);
  };

  const handleUpdateEnrichConfig = () => {
    updateBlock(getBlock(1));
  };

  const handleAttachBlock = async (workflowToAttach, workflowType, blockOptions, profile) => {
    const blockOptionsId2 = getBlock(2);
    let workflowToAttachCopy = { ...workflowToAttach[0] };
    if (workflowType === 'new') {
      workflowToAttachCopy = await handleCreateWorkflow(workflowToAttach[0]);
      if (!workflowToAttachCopy) return;
    }
    setWorkflowId(workflowToAttachCopy._id);
    if (blockOptionsId2._id || !workflowToAttachCopy._id) {
      if (
        checkPermission({
          permissions: profile.permissions,
          name: 'Workflow',
          action: 'Edit',
        })
      ) {
        if (!workflowToAttachCopy._id) return;
        if (blockOptionsId2.options) {
          blockOptionsId2.options.workflowId = workflowToAttachCopy._id;
          blockOptionsId2.options.name = workflowToAttachCopy.name;
        } else {
          blockOptionsId2.options = {
            workflowId: workflowToAttachCopy._id,
            name: workflowToAttachCopy.name,
          };
        }
        const { data, statusCode } = await updateBlock(blockOptionsId2);
        if (statusCode === 200) {
          console.log(data);
        }
      } else {
        toast.error(<FormattedMessage id={'permissions.noAccess'} />);
      }
    } else {
      let template = state.selectedTemplate;

      template.blocks = state.selectedTemplate.blocks.map((block) => {
        if (block.id === '2') {
          block.options.workflowId = workflowToAttachCopy._id;
        }
        return block;
      });
      dispatch({ type: actions.SELECTED_TEMPLATE, payload: template });
    }
  };

  const handleCreateWorkflow = async (intg) => {
    const { data } = await getTemplates();
    const myTemplate = data.templates.find(
      (template) => template.type == 'enrichment' || template.type === 'integration',
    );
    if (intg) {
      myTemplate.type = 'integration';
      myTemplate.blocks = [
        {
          id: 1,
          name: intg.name,
          action: 'integration-' + intg.name,
          type: 'flow_action',
          options: { email: '' },
        },
      ];
    }
    if (myTemplate.type == 'enrichment') {
      myTemplate.blocks = myTemplate.blocks.map((block) => {
        if (block.id == '1') {
          block.options.addLead = true;
          block.options.isEmailProRequired = state.enrichConfig.isEmailProRequired;
          block.options.isPhoneRequired = state.enrichConfig.isPhoneRequired;
          block.options.dataToGet = state.enrichConfig.dataToGet;
        }
        if (block.id == '2') {
          block.options.workflowId = state.selectedTemplate.blocks[1].options.workflowId;
        }
        return block;
      });
    }
    if (
      checkPermission({
        permissions: profile.permissions,
        name: 'Workflow',
        action: 'Edit',
      })
    ) {
      if (myTemplate.type == 'enrichment') {
        myTemplate.name = workflow.name;
      } else {
        myTemplate.name = intg.name;
      }
      const { data, statusCode } = await createWorkflow(myTemplate);

      if (statusCode === 200) {
        // setVerifyActivation(data.workflow);
        return data.workflow;
      }
    } else {
      toast.error(<FormattedMessage id={'permissions.noAccess'} />);
      // setVerifyActivation(null);
    }
  };

  const handleStepSave = async () => {
    if (workflow._id) {
      if (
        checkPermission({
          permissions: profile.permissions,
          name: 'Workflow',
          action: 'Edit',
        })
      ) {
        await updateBlock(workflow.blocks[0]);
        toast.success(<FormattedMessage id="successfullySaved" />);
      } else {
        toast.error(<FormattedMessage id="permissions.noAccess" />);
      }
    }
  };

  const handleRefreshAndCloseSettings = (save) => {
    if (save) return setBlockOptions(false);
    getWorkflow(workflow._id).then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({ type: actions.SELECTED_TEMPLATE, payload: data.workflow });
      }
    });
    setBlockOptions(false);
  };

  useEffect(() => {
    const loadData = async () => {
      const _integrations = allIntegrations.filter(
        (intg) => intg.active && intg.name !== 'linkedin',
      );
      let { data, statusCode } = await getAccounts();
      if (data && data.accounts)
        _integrations.map((intg) => {
          intg.isConnected = data.accounts[intg.name];
          return intg;
        });
      setIntegrations(_integrations);
    };

    loadData();

    const { addLead, ...configToUpdate } = workflow.blocks[0].options;

    dispatch({
      type: actions.SET_ENRICH_CONFIG,
      payload: {
        ...state.enrichConfig,
        ...configToUpdate,
      },
    });

    // Check if workflow is attached to an integration workflow
    getWorkflows({
      filters: {
        all: true,
        type: ['integration'],
      },
    }).then(({ data, statusCode }) => {
      if (statusCode === 200) {
        const integrationWorkflows = data.workflows.filter((wk) => wk.type === 'integration');
        let isAttached = false;
        if (integrationWorkflows.length > 0 && workflow.type === 'enrichment') {
          integrationWorkflows.map((intg, index) => {
            if (intg._id === workflow.blocks[1].options.workflowId) {
              setIsWkAttached(true);
              isAttached = true;
            }
          });
        }

        if (workflow.type === 'enrichment' && !isAttached) {
          let template = state.selectedTemplate;
          template.blocks[1].options = { workflowId: null };
          updateBlock(template.blocks[1]).then(({ data, statusCode }) => {
            if (statusCode === 200) {
              console.log('dataaaaa->', data);
              dispatch({ type: actions.SELECTED_TEMPLATE, payload: template });
            }
          });
        }
      }
    });
    dispatch({
      type: actions.SET_WORKFLOWS_DETAILS,
      payload: workflow,
    });

    return () => {
      dispatch({
        type: actions.SET_WORKFLOWS_DETAILS,
        payload: {},
      });
    };
  }, []);

  const handleMappingModal = (e) => {
    if (e) e.stopPropagation();
    setIsMappingModalOpen(!isMappingModalOpen);
  };

  const SELECTED_TEMPLATE = {
    enrichment: (
      <EnrichementTemplate
        blockOptions={blockOptions}
        handleSettingsToggle={(options) => setBlockOptions(options)}
        template={state.selectedTemplate}
        activeBlockId={blockOptions.id}
        closeSettingsToggle={handleRefreshAndCloseSettings}
      />
    ),
  };

  const handleSaveAndPublish = async () => {
    hanldeChangeWokrflowName();
    handleUpdateEnrichConfig();
    if (!isWkAttached) {
      if (state.selectedIntgDropdown && state.selectedIntgDropdown.length > 0) {
        await handleAttachBlock(
          state.selectedIntgDropdown,
          state.selectedIntgDropdown[0].type === 'integration' ? '' : 'new',
          blockOptions,
          profile,
        );
      }
      setIsWkAttached(true);
    }
  };

  const handleUpdateIntgName = () => {
    setCurrentWorkflowName(workflowName);
    hanldeChangeWokrflowName();
    toast.success(<FormattedMessage id="legacy.successfullyUpdated" />);
  };

  if (!state.selectedTemplate) {
    return <PreLoader />;
  }

  if (isConnectionModalOpen) {
    window.location.href = '/settings/integration';
  }

  return (
    <>
      <div
        id="workflow_tab"
        style={{ marginTop: '0 !important' }}
        className="tab-pane fade active show"
      >
        <Col md={'12'} className={'content-wrap ' + (navToggle ? 'expand' : '')}>
          <WorkflowTreeWrapper>
            <div
              style={{
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 12px 0 #24294629',
                borderRadius: '10px',
                padding: '24px',
              }}
              className="workflow-main-container"
            >
              <div id="salesautomationcontent">
                <div className="workflow-input-field">
                  {/*  GENERAL */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h1 style={{ fontSize: '20px', fontWeight: '500' }}>
                      <FormattedMessage id="workflows.general" />
                    </h1>
                    {workflow.type === 'enrichment' && (
                      <div className="save-publish-ui">
                        <a href="#" className="btn1" onClick={() => handleSaveAndPublish()}>
                          <FormattedMessage id={'workflow.saveAndPublish'} />
                        </a>
                      </div>
                    )}
                  </div>
                  <div>
                    <h3>
                      <FormattedMessage id={'workflow.workflowName'} />
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <FormattedMessage id={'workflow.workflowName'}>
                        {(placeholder) => (
                          <input
                            type="text"
                            id="wk-name-input"
                            defaultValue={workflow.name}
                            onChange={(e) => setWorkflowName(e.target.value)}
                            placeholder={placeholder}
                            required
                            ref={nameRef}
                          />
                        )}
                      </FormattedMessage>
                      {workflow.type === 'integration' && (
                        <button
                          disabled={workflowName === currentWorkflowName}
                          onClick={() => handleUpdateIntgName()}
                          className="btn btn1"
                        >
                          <FormattedMessage id="billing.update" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="owner-container">
                    <h3>
                      <FormattedMessage id="organization.owner" />
                    </h3>
                    <div className="owner">{workflow.creator}</div>
                  </div>
                  <div>
                    {/* TEST TEMPLATE SETTINGS */}
                    <TemplateSettings
                      // handleSettingsToggle={handleRefreshAndCloseSettings}
                      blockOptions={blockOptions}
                      setBlockOptions={setBlockOptions}
                      profile={profile}
                      organizationCredits={organizationCredits}
                      integrations={integrations}
                      setIsMappingModalOpen={setIsMappingModalOpen}
                      setIsConnectionModalOpen={setIsConnectionModalOpen}
                      handleSettingsToggle={(blockOptions) => setBlockOptions(blockOptions)}
                      setIsBlockAttached={setIsBlockAttached}
                      currentWorkflow={workflow}
                      isWkAttached={isWkAttached}
                      setIsWkAttached={setIsWkAttached}
                    />
                  </div>
                </div>
              </div>
              {SELECTED_TEMPLATE[workflow.type]}
              {workflow.type === 'integration' && (
                <button className="btn btn1" onClick={() => setIsMappingModalOpen(true)}>
                  <FormattedMessage id="workflow.configureMapping" />
                </button>
              )}
            </div>

            {/* DELETE WORKFLOW FOOTER */}
            <div id="workflow-delete-container">
              <div>
                <h2>
                  <FormattedMessage id="workflow.deleteMyWorkflow" />
                </h2>
                <p style={{ fontSize: '16px', lineHeight: '24px' }}>
                  <FormattedMessage
                    id={'workflow.onceWorkflowIsDeleted'}
                    values={{
                      span: (chunk) => <span style={{ fontWeight: 500 }}>{chunk}</span>,
                      br: <br />,
                    }}
                  />
                </p>
              </div>
              <button className="delete-button" onClick={() => setWorkflowToRemove(true)}>
                <FormattedMessage id="delete" />
              </button>
            </div>
          </WorkflowTreeWrapper>

          {/* MAPPING MODAL */}
          {isMappingModalOpen && (
            <MappingModal
              integration={
                workflow.type === 'enrichment' ? state.selectedIntegration : workflow.blocks[0].name
              }
              show={isMappingModalOpen}
              onHide={handleMappingModal}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </Col>
      </div>
    </>
  );
}

function WorkflowTreeWrapper({ children }) {
  return (
    <div className="content-block">
      <div className="inner-content-wrap">
        <div className="create-workflow-container">{children}</div>
      </div>
    </div>
  );
}
