import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteOrganization } from '../../../../../services/api/organization.api';
import { Modal } from 'react-bootstrap';
import { actions, OrganizationContext } from '../../../store/Organization.store';

export default function DeleteOrganizationModal({ handleAcceptInvitation }) {
  const { state, dispatch } = useContext(OrganizationContext);
  const [textToDeleteOrg, setText] = useState('');
  const [error, setError] = useState(false);
  const intl = useIntl();

  const handleSubmit = async () => {
    if (textToDeleteOrg === intl.formatMessage({ id: 'organization.deleteThisOrganization' })) {
      const { data, statusCode } = await deleteOrganization();
      if (statusCode === 200) {
        dispatch({
          type: actions.SET_DELETE_TEAM_MODAL,
          payload: { deleteTeamModal: false },
        });
        handleAcceptInvitation(state.selectedInvitation);
      } else {
        setError(data.messageTranslated || data.message);
      }
    } else {
      setError(intl.formatMessage({ id: 'organization.pleaseEnterTheValidPhrase' }));
    }
  };

  return (
    <Modal
      show={true}
      onHide={() =>
        dispatch({
          type: actions.SET_DELETE_TEAM_MODAL,
          payload: { deleteTeamModal: false },
        })
      }
      className="fade delete-organization-wrapper"
      id="delete-organization"
    >
      <Modal.Header closeButton className="border-0">
        <button type="button" className="close" data-dismiss="modal">
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <h4 className="title">
            <FormattedMessage id="organization.deleteOrganization" />
          </h4>
        </div>
      </Modal.Header>
      <div className="modal-body p-24">
        <div className="delete-inner-ui">
          <div className="alert-wrap">
            <span className="ic-alert">
              <img src="/assets/images/ic-alert.svg" alt="" />
            </span>
            <label>
              <FormattedMessage id="organization.yourAboutToDeleteThisOrg" />
            </label>
            <p>
              <FormattedMessage
                id="organization.onceOrgIsDeleted"
                values={{ span: (chunk) => <span>{chunk}</span>, br: <br /> }}
              />
            </p>
          </div>
          <p>
            <FormattedMessage
              id="organization.thisActionCannotBeUndone"
              values={{ span: (chunk) => <span>{chunk}</span>, br: <br /> }}
            />
          </p>
          <div className="form-fields">
            <a href="#" className="delete-tag">
              “<FormattedMessage id="organization.deleteThisOrganization" />”
            </a>
            <div className="form-group">
              <FormattedMessage id="organization.typeHere">
                {(placeHolder) => (
                  <input
                    type="text"
                    placeholder={placeHolder}
                    onChange={(e) => setText(e.target.value)}
                    style={
                      error ? { border: '1px solid red', color: '#981830' } : { color: '#981830' }
                    }
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
      {error && <div className="btn-grp text-center error kaspr-form-text-error">{error}</div>}
      <div className="modal-footer border-0">
        <a
          href="#"
          className="btn6"
          onClick={() =>
            dispatch({
              type: actions.SET_DELETE_TEAM_MODAL,
              payload: { deleteTeamModal: false },
            })
          }
        >
          <FormattedMessage id="organization.cancelKeepIt" />
        </a>
        <a href="#" className="btn5 ml-20" onClick={handleSubmit}>
          <FormattedMessage id="organization.yesDeleteThisOrganization" />
        </a>
      </div>
    </Modal>
  );
}
