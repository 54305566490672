import React, { useEffect, useState } from 'react';
import { usePermissions } from '../../../../components/Permissions/permissions.hooks';
import { getAccounts } from '../../../../services/api/integrations.api';
import { getSynchronizedAccount } from '../../../../services/api/linkedin.api';
import { FormattedMessage, useIntl } from 'react-intl';
import IntegrationCard from '../IntegrationCard/IntegrationCard';
import ConnectionModal from '../Modals/ConnectionModal/ConnectionModal';
import { ModalMessage } from '../../../../components';
import MappingModal from '../Modals/MappingModal/MappingModal';
import ApiIntegrationModal from '../Modals/ApiIntegrationModal';
import { services } from '../constants';
import './AvailableIntegrations.css';
import { EXTENSION_ID } from '../../../../constants';

function AvailableIntegrations({ profile }) {
  const [accounts, setAccounts] = useState({
    hubspot: null,
    salesforce: null,
    sendinblue: null,
    lemlist: null,
    pipedrive: null,
    zapier: null,
    linkedin: null,
    aircall: null,
    zohocrm: null,
  });
  const [selectedIntegration, setSelectedIntegration] = useState({
    integration: '',
    category: '',
    imagesrc: '',
    show: '',
    onHide: '',
    account: '',
    moreInfoLink: '',
    confirmConnect: '',
    disconnectAccount: '',
  });
  const [connectionError, setConnectionError] = useState(false);
  const [connectionErrorMessage, setConnectionErrorMessage] = useState(null);
  const [showMappingModal, setMappingModal] = useState(false);
  const [showApiIntegration, setShowApiIntegration] = useState(false);
  const [showConnectionModal, setConnectionModal] = useState(false);
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [checkPlanPermissions, checkMemberPermissions] = usePermissions(
    null,
    profile && profile.permissions,
    'Integration',
  );
  const intl = useIntl();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {}, []);
  const loadData = async () => {
    let { data } = await getAccounts();
    let acc = data.accounts;
    let {
      data: { session },
    } = await getSynchronizedAccount();
    if (session) acc.linkedin = { linkedinEmail: session.id };
    setAccounts(acc);
  };

  const handleShowApiIntegration = (e) => {
    if (e) e.stopPropagation();
    setShowApiIntegration(!showApiIntegration);
  };

  const handleMappingModal = (e) => {
    if (e) e.stopPropagation();
    setMappingModal(!showMappingModal);
  };

  const handleConnectionModal = (e) => {
    if (e) e.stopPropagation();
    let userFields = profile;
    if (userFields.hideWidget) {
      userFields.hideWidget = false;
      if (window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage) {
        window.chrome.runtime.sendMessage(EXTENSION_ID, {
          action: 'SET_WIDGET_STATUS',
          input: { userFields, wasHidden: true },
        });
      }
    }
    setIsConnectionModalOpen(!isConnectionModalOpen);
    setConnectionModal(!showConnectionModal);
  };

  const handleConnectionErrorModal = (e) => {
    if (e) e.stopPropagation();
    setConnectionError(!connectionError);
    if (isConnectionModalOpen) handleConnectionModal();
  };

  const confirmConnect = (integration, newAccount, err) => {
    if (!newAccount || newAccount === 'null' || newAccount === 'undefined') {
      if (err === 'null' || err === 'undefined') err = null;
      setConnectionErrorMessage(err);
      handleConnectionErrorModal();
      return;
    }
    let updatedAccounts = accounts;
    let updatedIntegration = {};
    updatedIntegration[integration + 'Email'] = newAccount;
    updatedAccounts[integration] = updatedIntegration;
    setAccounts(updatedAccounts);
    setConnectionModal(false);
    handleMappingModal();
  };

  const confirmDisconnect = (integration) => {
    let updatedAccounts = accounts;
    let updatedIntegration = {};
    updatedIntegration[integration + 'Email'] = false;
    updatedAccounts[integration] = updatedIntegration;
    setAccounts(updatedAccounts);
  };

  const allIntegrationCards = [
    {
      key: 'linkedin',
      category: 'NETWORKING',
      name: 'linkedin',
      textId: 'settings.integration.linkedinText',
      imagesrc: '/assets/images/linkedin.png',
      account: accounts.linkedin && accounts.linkedin.linkedinEmail,
      moreInfoLink: 'https://www.linkedin.com/',
      disconnectAccount: () => confirmDisconnect('linkedin'),
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: handleMappingModal,
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
    },
    {
      key: 'hubspot',
      category: 'CRM',
      name: 'hubspot',
      textId: 'settings.integration.hubspotText',
      imagesrc: '/assets/images/hubspot.png',
      account: accounts.hubspot && accounts.hubspot.hubspotEmail,
      moreInfoLink: 'https://www.hubspot.com/',
      setAccount: (newAccount, err) => confirmConnect('hubspot', newAccount, err),
      disconnectAccount: () => confirmDisconnect('hubspot'),
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: handleMappingModal,
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
      checkMemberPermissions: checkMemberPermissions,
    },
    {
      key: 'salesforce',
      category: 'CRM',
      name: 'salesforce',
      textId: 'settings.integration.salesforceText',
      imagesrc: '/assets/images/salesforce.png',
      account: accounts.salesforce && accounts.salesforce.salesforceEmail,
      moreInfoLink: 'https://www.salesforce.com/',
      setAccount: (newAccount, err) => confirmConnect('salesforce', newAccount, err),
      disconnectAccount: () => confirmDisconnect('salesforce'),
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: handleMappingModal,
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
      checkMemberPermissions: checkMemberPermissions,
    },
    {
      key: 'sendinblue',
      category: 'Email Newsletters',
      name: 'sendinblue',
      textId: 'settings.integration.sendinblueText',
      imagesrc: '/assets/images/brevo.png',
      account: accounts.sendinblue && accounts.sendinblue.sendinblueEmail,
      moreInfoLink: 'https://www.sendinblue.com/',
      setAccount: (newAccount, err) => confirmConnect('sendinblue', newAccount, err),
      apiKeyLink: 'https://account.sendinblue.com/advanced/api',
      disconnectAccount: () => confirmDisconnect('sendinblue'),
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: handleMappingModal,
      handleShowApiIntegration: handleShowApiIntegration,
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
      checkMemberPermissions: checkMemberPermissions,
    },
    {
      key: 'pipedrive',
      category: 'CRM',
      name: 'pipedrive',
      textId: 'settings.integration.pipedriveText',
      imagesrc: '/assets/images/pipedrive.png',
      account: accounts.pipedrive && accounts.pipedrive.pipedriveEmail,
      moreInfoLink: 'https://www.pipedrive.com/',
      setAccount: (newAccount, err) => confirmConnect('pipedrive', newAccount, err),
      apiKeyLink: 'https://app.pipedrive.com/settings/api',
      disconnectAccount: () => confirmDisconnect('pipedrive'),
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: handleMappingModal,
      handleShowApiIntegration: handleShowApiIntegration,
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
      checkMemberPermissions: checkMemberPermissions,
    },
    {
      key: 'lemlist',
      category: 'Newsletters',
      name: 'lemlist',
      textId: 'settings.integration.lemlistText',
      imagesrc: '/assets/images/lemlist.png',
      account: accounts.lemlist && accounts.lemlist.lemlistEmail,
      moreInfoLink: 'https://www.lemlist.com/',
      setAccount: (newAccount, err) => confirmConnect('lemlist', newAccount, err),
      apiKeyLink: 'https://app.lemlist.com/settings/integrations',
      disconnectAccount: () => confirmDisconnect('lemlist'),
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: handleMappingModal,
      handleShowApiIntegration: handleShowApiIntegration,
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
      checkMemberPermissions: checkMemberPermissions,
    },
    {
      key: 'zapier',
      category: 'Integration',
      name: 'zapier',
      textId: 'settings.integration.zapierText',
      imagesrc: '/assets/images/zapier.png',
      account: accounts.zapier && accounts.zapier.zapierEmail,
      moreInfoLink: 'https://zapier.com/',
      disconnectAccount: () => {},
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: () => {},
      handleShowApiIntegration: () => {},
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
      checkMemberPermissions: checkMemberPermissions,
    },
    {
      key: 'zohocrm',
      category: 'CRM',
      name: 'zohocrm',
      textId: 'settings.integration.zohoCrmText',
      imagesrc: '/assets/images/zoho.png',
      account: accounts.zohocrm && accounts.zohocrm.zohocrmEmail,
      moreInfoLink: 'https://www.zoho.com/',
      setAccount: (newAccount, err) => confirmConnect('zohocrm', newAccount, err),
      disconnectAccount: () => confirmDisconnect('zohocrm'),
      handleConnectionModal: handleConnectionModal,
      handleMappingModal: handleMappingModal,
      selectedIntegration: selectedIntegration,
      setSelectedIntegration: setSelectedIntegration,
      isLoading: isLoading,
      checkMemberPermissions: checkMemberPermissions,
    },
  ];

  const filteredIntegrationCards = allIntegrationCards.filter((card) =>
    card.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div id="available-integrations" className="integrations-tool-wrapper available-tool">
      <div className="header-block top-integration-title">
        <p>
          <FormattedMessage id={'settings.integration.available'} />
        </p>
        <input
          type="text"
          placeholder={intl.formatMessage({
            id: 'settings.integration.searchIntegrationsHere',
          })}
          value={searchValue}
          onChange={handleSearchChange}
        />
      </div>
      <div className="row content-listing">
        {filteredIntegrationCards.length === 0 ? (
          <div className="request-integration">
            <h2>
              <FormattedMessage id={'settings.integration.noIntegrationFound'} />
            </h2>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScoB4YOYGDBEPTjjEkGUokdKk8BiOR-42CtZrEG4MLj5y2bAw/viewform"
              target="_blank"
              className="btn1"
            >
              <FormattedMessage id="settings.integration.requestIntegration" />
            </a>
          </div>
        ) : (
          filteredIntegrationCards.map((card) => (
            <IntegrationCard
              key={card.key}
              category={card.category}
              name={card.name}
              text={<FormattedMessage id={card.textId} />}
              imagesrc={card.imagesrc}
              account={card.account}
              moreInfoLink={card.moreInfoLink}
              disconnectAccount={card.disconnectAccount}
              handleConnectionModal={card.handleConnectionModal}
              handleMappingModal={card.handleMappingModal}
              selectedIntegration={selectedIntegration}
              setSelectedIntegration={setSelectedIntegration}
              isLoading={isLoading}
              {...(card.setAccount ? { setAccount: card.setAccount } : {})}
              {...(card.apiKeyLink ? { apiKeyLink: card.apiKeyLink } : {})}
              {...(card.handleShowApiIntegration
                ? { handleShowApiIntegration: card.handleShowApiIntegration }
                : {})}
              {...(card.checkMemberPermissions
                ? { checkMemberPermissions: card.checkMemberPermissions }
                : {})}
            />
          ))
        )}
      </div>
      <div>
        {showConnectionModal && (
          <ConnectionModal
            integration={selectedIntegration.integration}
            category={selectedIntegration.category}
            imagesrc={selectedIntegration.imagesrc}
            show={handleConnectionModal}
            onHide={handleConnectionModal}
            account={selectedIntegration.account}
            disconnectAccount={() => confirmDisconnect(selectedIntegration.integration)}
            handleShowApiIntegration={handleShowApiIntegration}
            handleMappingModal={handleMappingModal}
            moreInfoLink={selectedIntegration.moreInfoLink}
            confirmConnect={(newAccount, err) =>
              confirmConnect(selectedIntegration.integration, newAccount, err)
            }
            checkMemberPermissions={checkMemberPermissions}
          />
        )}
        <ModalMessage
          toggle={connectionError}
          setToggle={handleConnectionErrorModal}
          title={<FormattedMessage id={'connectionFailed'} />}
          textElement={
            <>
              {connectionErrorMessage || (
                <FormattedMessage id={'settings.integration.anErrorHasOccurred'} />
              )}
              {connectionErrorMessage ===
                'The REST API permission is disabled on your Salesforce account' && (
                <a target="_blank" href="https://help.salesforce.com/home">
                  <FormattedMessage id={'settings.integration.clickHereToContact'} />
                </a>
              )}
            </>
          }
          action={'OK'}
          handleAction={handleConnectionErrorModal}
          forbidCancel={true}
        />
        {accounts &&
          accounts[selectedIntegration.integration] &&
          accounts[selectedIntegration.integration][selectedIntegration.integration + 'Email'] &&
          (services[selectedIntegration.integration] === 'oauth' ||
            services[selectedIntegration.integration] === 'apikey') && (
            <MappingModal
              integration={selectedIntegration.integration}
              category={selectedIntegration.category}
              imagesrc={selectedIntegration.imagesrc}
              show={showMappingModal}
              onHide={handleMappingModal}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        {services[selectedIntegration.integration] === 'oauth' && showApiIntegration ? (
          <div />
        ) : (
          <ApiIntegrationModal
            integration={selectedIntegration.integration}
            imagesrc={selectedIntegration.imagesrc}
            show={showApiIntegration}
            onHide={handleShowApiIntegration}
            handleMappingModal={handleMappingModal}
            confirmConnect={(newAccount, err) =>
              confirmConnect(selectedIntegration.integration, newAccount, err)
            }
            apiKeyLink={selectedIntegration.apiKeyLink}
          />
        )}
      </div>
    </div>
  );
}

export default AvailableIntegrations;
