import React, { useReducer, useMemo, useEffect, useState } from 'react';
import { homeReducer, homeInitialState } from './store/home.store';
import { FormattedMessage } from 'react-intl';
import HomeProvider from './store/home.provider';
import './Home.css';
import { Header, ContentWrapperUi, SideNav, PreLoader } from '../../components';
import { Row, Col, OverlayTrigger } from 'react-bootstrap';
import { leadsFilter, searchTask } from '../../services/api/leads.api';
import actions from './store/home.actions';
import { ToastContainer } from 'react-toastify';
import HomeContent from './HomeContent/HomeContent';
import { OnBoardingSideModal, Onboarding } from './Onboarding/Onboarding';
import { useUserSteps } from './Home.hooks';
import { getWorkflows } from '../../services/api/workflows.api';
import { getNotifications } from '../../services/api/notifications.api';
import SettingsProvider from '../Settings/store/Settings.provider';

/**
 * Home page
 * @component
 * @param props {Object} redux props
 * @returns {JSX.Element} The Home page
 */
function Home(props) {
  const [state, dispatch] = useReducer(homeReducer, homeInitialState);
  const [isAdmin, setIsAdmin] = useState(null);
  const [onboardingToggle, setOnbordingToggle] = useState(null);
  const {
    steps,
    selectedStep,
    setSelectedStep,
    onSendInvitations,
    onDeleteInvitations,
    onCreateWorkspace,
    urlSegment,
    location,
    handleSkipSteps,
  } = useUserSteps(props.redux.profile);
  const [currentTab, setCurrentTab] = useState('home');
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  let isVersion2 = props.redux.credits && props.redux.credits.isVersion(2);
  useEffect(() => {
    if (!isVersion2) return handleToggle('home');
    if (urlSegment || location.pathname == '/onboarding' || props.redux.profile.nextStep == 3) {
      handleToggle('onboarding');
    } else {
      const sideNavIcon = document.querySelector('#onboarding-icon');
      if (sideNavIcon) {
        sideNavIcon.classList.remove('onBoarding-home-icon-change-style');
      }
    }
  }, [state]);

  useEffect(() => {
    if (
      props.redux.organization &&
      props.redux.organization.organization &&
      props.redux.organization.organization.members
    ) {
      if (
        props.redux.organization.member.type == 'owner' ||
        props.redux.organization.member.type == 'admin'
      ) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
    dispatch({
      type: actions.LEADS_LOADING,
      payload: true,
    });
    leadsFilter({}).then(({ data }) => {
      dispatch({ type: actions.GET_LEADS, payload: data });
      dispatch({
        type: actions.LEADS_LOADING,
        payload: false,
      });
    });
    searchTask().then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({ type: actions.GET_TASKS, payload: data });
      }
    });
    getWorkflows().then(({ data, statusCode }) => {
      if (statusCode == 200) {
        dispatch({ type: actions.GET_WORKFLOWS, payload: data });
      }
    });
    getNotifications().then(({ data }) => {
      dispatch({ type: actions.GET_NOTIFICATIONS, payload: data });
    });

    const params = new URLSearchParams(window.location.search);
    const openChatbot = params.get('openChatbot');
    if (openChatbot === 'true') {
      const chatButton = document.querySelector('.intercom-launcher');
      if (chatButton) {
        chatButton.click();
      }
    }
  }, []);

  if (!state.leads || !props.redux.credits.remaining) {
    return <PreLoader />;
  }
  function handleToggle(type) {
    const sideNavElement = document.querySelector('.side-nav-container');
    const logoElement = document.querySelector('.site-logo');
    const expandElement = document.querySelector('.content-wrap');
    const sideNavIcon = document.querySelector('#onboarding-icon');
    if (sideNavIcon) {
      if (type == 'onboarding') {
        sideNavIcon.classList.add('active');
        sideNavIcon.classList.add('onBoarding-home-icon-change-style');
      } else {
        sideNavIcon.classList.remove('onBoarding-home-icon-change-style');
      }
    }
    if (sideNavElement) {
      sideNavElement.classList[type == 'onboarding' ? 'add' : 'remove']('fix-0-top');

      sideNavElement.classList[type == 'onboarding' ? 'add' : 'remove']('small-navbar');
    }

    if (logoElement) logoElement.classList[type == 'onboarding' ? 'add' : 'remove']('s-logo');
    if (expandElement) expandElement.classList[type == 'onboarding' ? 'add' : 'remove']('expand');
    setOnbordingToggle(type == 'onboarding');
    setCurrentTab(type);

    if (type == 'home') window.history.pushState(null, null, '/home/');
    else window.history.pushState(null, null, '/onboarding/' + (urlSegment || 'discover'));
  }

  return (
    <HomeProvider store={store}>
      <SettingsProvider store={store}>
        <Header
          currentPage={<FormattedMessage id={'sidenav.home'} />}
          profile={props.redux.profile}
          credits={props.redux.credits}
          remaining={props.redux.credits.remaining}
          organization={props.redux.organization}
        />
        <ContentWrapperUi>
          <SideNav navToggle={props.redux.globalReducer.navToggle} isOnboarding />
          <Col md={10} className="content-wrap">
            <div className={'home-container'}>
              {onboardingToggle && isVersion2 && (
                <OnBoardingSideModal
                  onTabClick={handleToggle}
                  onStepClick={setSelectedStep}
                  steps={steps}
                  selectedStep={selectedStep}
                  handleSkipSteps={handleSkipSteps}
                />
              )}
              <div className="content-block">
                <div className="inner-content-wrap dashboard-warpper">
                  <div className="wd-wrapper">
                    <HomeNav
                      onTabClick={handleToggle}
                      stepsAreDone={selectedStep === 'all_done'}
                      currentTab={currentTab}
                      isVersion2={isVersion2}
                    />
                    <div className="tab-content">
                      {currentTab === 'home' && (
                        <HomeContent
                          {...props}
                          isAdmin={isAdmin}
                          leads={state.leads}
                          tasks={state.tasks}
                          workflows={state.workflows}
                          notifications={state.notifications}
                          organization={
                            props.redux.organization && props.redux.organization.organization
                          }
                          user={props.redux.profile}
                          subOrganizations={
                            props.redux.organization && props.redux.organization.subOrganizations
                          }
                          credits={props.redux.credits}
                        />
                      )}
                      {currentTab == 'onboarding' && isVersion2 && (
                        <Onboarding
                          selectedStep={selectedStep}
                          steps={steps}
                          sendInvitations={onSendInvitations}
                          deleteInvitations={onDeleteInvitations}
                          createWorkSpace={onCreateWorkspace}
                          user={props.redux.profile}
                          organization={
                            props.redux.organization && props.redux.organization.organization
                          }
                          remaining={props.redux.credits.remaining}
                          organizationCredits={props.redux.credits.organizationCredits}
                          personalCredits={props.redux.credits.personalCredits}
                          userInfo={props.redux.profile}
                          onCreateWorkspace={onCreateWorkspace}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </ContentWrapperUi>
        <ToastContainer />
      </SettingsProvider>
    </HomeProvider>
  );
}
function HomeNav({ onTabClick, stepsAreDone, currentTab, isVersion2 }) {
  /*  useEffect(() => {
    const onboarding = document.querySelector("#home-onboarding");
    if (onboarding) onboarding.click();
  }, []);*/
  const handleExpand = () => {
    const sideNavElement = document.querySelector('.side-nav-container');
    const logoElement = document.querySelector('.site-logo');
    const expandElement = document.querySelector('.content-wrap');

    if (sideNavElement) sideNavElement.classList.add('small-navbar');
    if (logoElement) logoElement.classList.add('s-logo');
    if (expandElement) expandElement.classList.add('expand');
  };
  if (stepsAreDone || !isVersion2) {
    return (
      <div className="workflow-detail-header">
        <div className="cstm-tabs-ui cs-tabs-slider">
          <ul className="nav nav-tabs">
            <li onClick={() => onTabClick('home')}>
              <a id="home" data-toggle="tab" href="#dashboard_tab" className="active">
                <FormattedMessage id={'tabs.yourDashboard'} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li onClick={() => onTabClick('home')}>
            <a
              id="home"
              data-toggle="tab"
              href="#dashboard_tab"
              className={currentTab == 'home' ? 'active' : ''}
            >
              <FormattedMessage id={'tabs.yourDashboard'} />
            </a>
          </li>
          <li onClick={() => onTabClick('onboarding')}>
            <a
              id="home-onboarding"
              data-toggle="tab"
              href="#onboarding_tab"
              className={currentTab == 'onboarding' ? 'active' : ''}
            >
              <FormattedMessage id={'home.onboarding'} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Home;
