import React from 'react';
import './OnboardingFooter.css';
import { FormattedMessage } from 'react-intl';

const OnboardingFooter = () => {
  return (
    <div id="onboarding-footer">
      {' '}
      <p className="copyright">
        ©2023 Kaspr ·{' '}
        <a className="copyright" target="_blank" href="https://kaspr.io/privacy-policy/">
          <FormattedMessage id={'auth.privacy'} />
        </a>{' '}
        &amp;{' '}
        <a className="copyright" target="_blank" href="https://kaspr.io/terms/">
          <FormattedMessage id={'auth.terms'} />
        </a>
      </p>
    </div>
  );
};

export default OnboardingFooter;
