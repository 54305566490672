import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './App.media.css';
import * as Page from './container';
import { ErrorBar, PreLoader, SideNav } from './components';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import actions from './redux/actions';
import { getToken } from './services/utils';
import AuthComplete from './container/Auth/AuthServ/AuthComplete';
import { getCredits, getRemaining } from './services/api/credits.api';
import { CHROME_STORE, EXTENSION_ID, IS_PROD, SOURCE_BUSTER_CONFIG } from './constants';
import { getNotifications } from './services/api/notifications.api';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import TrackingEvents from './services/TrackingEvents/TrackingEvents';
import LegacyPopup from './components/LegacyPopup/LegacyPopup';
import 'react-toastify/dist/ReactToastify.css';
import { getOrganization, updateMemberInfos } from './services/api/organization.api';
import { getUser } from './services/api/auth.api';
import reduxActions from './redux/actions';
import { credits } from './redux/reducers/credits';

import sbjs from 'sourcebuster';
import cookie from 'react-cookies';

function App(reduxProps) {
  const [barError, setBarrError] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    let sb_utm = cookie.load('sbjs_first', { path: '/', domain: 'kaspr.io' });
    if (!sb_utm) sbjs.init(SOURCE_BUSTER_CONFIG);

    if (getToken()) {
      getNotifications().then(({ data }) => {
        if (data.banner) {
          setTimeout(() => {
            setBarrError(data.banner);
          }, 1000);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (
      reduxProps.redux.credits.organizationCredits &&
      reduxProps.redux.credits.organizationCredits.disabled
    ) {
      setBarrError({
        descriptionText: intl.formatMessage({
          id: 'permissions.weHaveAProblemWithYourPurchase',
        }),
      });
    }
  }, [reduxProps.redux.credits]);
  const reactIntl = useIntl();
  useEffect(() => {
    let { redux, intl } = reduxProps;
    if (!intl) intl = reactIntl;
    if (
      redux.profile.steps &&
      window.location.href.indexOf('welcome') == -1 &&
      window.location.href.indexOf('emailvalidation') == -1
    ) {
      const pluginInstallation = redux.profile.steps.find((stp) => stp.id === 'pluginInstallation');
      const linkedinValidation = redux.profile.steps.find((stp) => stp.id === 'linkedinValidation');

      if (pluginInstallation && !pluginInstallation.done) {
        return setBarrError({
          descriptionText: intl.formatMessage({
            id: 'permissions.ifYouWantToUseKasprPleaseDownloadThePlugin',
          }),
          link: CHROME_STORE,
          buttonText: (
            <>
              plugin
              <img width={13} src="/assets/images/download-solid.svg" alt="" />
            </>
          ),
          type: 'error',
        });
      }
      if (linkedinValidation && !linkedinValidation.done) {
        return setBarrError({
          descriptionText: intl.formatMessage({
            id: 'permissions.youHaveToConnectYourAccountWithLinkedin',
          }),
          link: 'https://www.linkedin.com/feed/?synchro=kaspr&isFirstKasprSynchro=true',
          buttonText: (
            <>
              <FormattedMessage id={'connect'} />
              <img width={13} src="/assets/images/ic-linkedin.png" alt="" />
            </>
          ),
          type: 'error',
        });
      }
    }
    if (window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage && IS_PROD) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          action: 'isKasprExtensionInstalled',
        },
        async (response, err) => {
          if (response === undefined) {
            return setBarrError({
              descriptionText: intl.formatMessage({
                id: 'permissions.ifYouWantToUseKasprPleaseDownloadThePlugin',
              }),
              link: CHROME_STORE,
              buttonText: (
                <>
                  LinkedIn Extension{' '}
                  <img width={13} src="/assets/images/download-solid.svg" alt="" />
                </>
              ),
              type: 'error',
            });
          }
        },
      );
    }
    if (reduxProps.redux.profile && window.intercomeGetUser) {
      window.intercomeGetUser(reduxProps.redux.profile);
    }
  }, [reduxProps.redux.profile]);
  if (!getToken()) {
    return (
      <TrackingEvents onTrack={console.log}>
        <BrowserRouter>
          <Switch>
            <Route path={'/signin'} exact render={(props) => <Page.Signin {...props} />} />
            <Route path={'/signup'} exact render={(props) => <Page.Signup {...props} />} />
            <Route
              path={'/signup/confirm'}
              exact
              render={(props) => <Page.SignupFromLinkParams {...props} />}
            />
            <Route path={'/signup/:email'} exact render={(props) => <Page.Signup {...props} />} />
            <Route path={'/forgot'} exact render={(props) => <Page.ForgotPasswd {...props} />} />
            <Route path={'/reset'} exact render={(props) => <Page.ResetPasswd {...props} />} />

            <Route path={'/optout'} exact render={(props) => <Page.OptOut />} />
            <Route path={'/optoutAutomatic'} exact render={(props) => <Page.OptOutFromEmail />} />

            <Route path={'/checkdevice'} exact render={(props) => <Page.ChekDevice {...props} />} />
            <Route render={() => <Redirect to="/signin" />} />
          </Switch>
        </BrowserRouter>
      </TrackingEvents>
    );
  }
  const redux = { ...reduxProps, ...reduxProps.redux };
  delete redux.redux;
  const isAdminWithSubscription = () => {
    const member = redux.organization && redux.organization.member;
    if (!member) return true;
    if (member && member.type !== 'member') return true;
    /*  return !(
      member &&
      member.type == "member" &&
      redux.credits.organizationCredit &&
      redux.credits.organizationCredits.subscription
    );*/
  };
  if (redux.credits.userBlocked) {
    return <Page.UserBlocked />;
  }
  if (!redux.profile.email) {
    return <PreLoader />;
  }

  if (redux.credits && !redux.credits.personalCredits && redux.profile.step === 0) {
    return <PreLoader />;
  }
  if (redux.profile.nextStep === -1 && !redux.credits.remaining) {
    return <PreLoader />;
  }
  const nextStep = redux.profile.nextStep;
  const showLegacyPopup =
    (((nextStep === -1 || nextStep > 2) &&
      redux.credits.organizationCredits &&
      window.location.pathname.indexOf('billing') === -1 &&
      redux.credits.showPopUp) ||
      window.location.href.indexOf('legacy') > -1) &&
    redux.credits &&
    redux.credits.isVersion(1);

  if (redux.organization.isPlanSuspended) {
    return (
      <TrackingEvents>
        <BrowserRouter>
          <Switch>
            <Route path={'/'} exact render={(props) => <Page.Unpaid {...redux} />} />
            {/*  <Route
              path={"/checkout"}
              exact
              render={(props) => (
                <Page.Billing {...props} redux={redux} urlSegment={"plans"} />
              )}
            />*/}

            <Route
              path={'/notify'}
              exact
              render={(props) => <Page.NotifySuccess {...props} redux={redux} />}
            />
            <Route path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        </BrowserRouter>
      </TrackingEvents>
    );
  }

  return (
    <TrackingEvents>
      <BrowserRouter>
        {showLegacyPopup && (
          <LegacyPopup.Choice
            organizationCredits={redux.credits.organizationCredits}
            currentPlan={redux.credits.organizationCredits.plan}
          />
        )}
        {barError && <ErrorBar info={barError} onHide={setBarrError} />}
        <div className="App">
          {(nextStep === -1 || nextStep > 2) && (
            <Switch>
              <Route
                path={'/home'}
                exact
                render={(props) => <Page.Home {...props} redux={redux} />}
              />
              <Route
                path={'/onboarding'}
                exact
                render={(props) => <Page.Home {...props} redux={redux} />}
              />
              <Route
                path={'/onboarding/:step'}
                exact
                render={(props) => <Page.Home {...props} redux={redux} />}
              />
              <Route
                path={'/leads'}
                exact
                render={(props) => <Page.Leads {...props} redux={redux} />}
              />
              <Route
                path={'/bulk-enrichment'}
                exact
                render={(props) => <Page.BulkEnrichment {...props} redux={redux} />}
              />
              <Route
                path={'/bulk-enrichment/files'}
                exact
                render={(props) => (
                  <Page.BulkEnrichment {...props} redux={redux} urlSegment={'files'} />
                )}
              />
              <Route
                path={'/bulk-enrichment/file-upload'}
                exact
                render={(props) => (
                  <Page.BulkEnrichment {...props} redux={redux} urlSegment={'file-upload'} />
                )}
              />
              <Route
                path={'/leads/waitinglist'}
                exact
                render={(props) => (
                  <Page.Leads {...props} redux={redux} urlSegment={'waitinglist'} />
                )}
              />
              <Route
                path={'/tasks'}
                exact
                render={(props) => <Page.Tasks {...props} redux={redux} />}
              />
              <Route
                path={'/lead/:leadId'}
                exact
                render={(props) => <Page.Leads {...props} redux={redux} />}
              />
              <Route
                path={'/workflow'}
                exact
                render={(props) => <Page.Workflow {...props} redux={redux} />}
              />
              <Route
                path={'/workflow/:id'}
                exact
                render={(props) => <Page.Workflow {...props} redux={redux} />}
              />
              <Route
                path={'/createworkflow/:name/:integration'}
                exact
                render={(props) => <Page.CreateWorkflow {...props} redux={redux} />}
              />
              <Route
                path={'/createworkflow/:name'}
                render={(props) => <Page.CreateWorkflow {...props} redux={redux} />}
              />
              <Route
                path={'/organization'}
                exact
                render={(props) => <Page.Organization {...props} redux={redux} />}
              />
              <Route
                path={'/organization/createjoin'}
                exact
                render={(props) => <Page.CreateOrganization {...props} redux={redux} />}
              />
              <Route
                path={'/workspace'}
                exact
                render={(props) => <Page.Organization {...props} redux={redux} />}
              />
              <Route
                path={'/workspace/createjoin'}
                exact
                render={(props) => <Page.CreateOrganization {...props} redux={redux} />}
              />
              <Route
                path={'/workspace/members'}
                exact
                render={(props) => (
                  <Page.Organization {...props} redux={redux} urlSegment={'members'} />
                )}
              />
              <Route
                path={'/workspace/statistics'}
                exact
                render={(props) => (
                  <Page.Organization {...props} redux={redux} urlSegment={'statistics'} />
                )}
              />
              <Route
                path={'/workspace/activity'}
                exact
                render={(props) => (
                  <Page.Organization {...props} redux={redux} urlSegment={'activity'} />
                )}
              />
              <Route
                path={'/workspace/settings'}
                exact
                render={(props) => (
                  <Page.Organization {...props} redux={redux} urlSegment={'settings'} />
                )}
              />
              <Route
                path={'/settings'}
                exact
                render={(props) => <Page.Settings {...props} redux={redux} />}
              />

              <Route
                path={'/settings/profile'}
                exact
                render={(props) => (
                  <Page.Settings {...props} redux={redux} urlSegment={'profile'} />
                )}
              />
              <Route
                path={'/settings/workspace'}
                exact
                render={(props) => (
                  <Page.Settings {...props} redux={redux} urlSegment={'workspace'} />
                )}
              />
              <Route
                path={'/settings/integration'}
                exact
                render={(props) => (
                  <Page.Settings {...props} redux={redux} urlSegment={'integration'} />
                )}
              />
              <Route
                path={'/settings/api'}
                exact
                render={(props) => <Page.Settings {...props} redux={redux} urlSegment={'api'} />}
              />
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing'}
                  exact
                  render={(props) => <Page.Billing {...props} redux={redux} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/info'}
                  exact
                  render={(props) => <Page.Billing {...props} redux={redux} urlSegment={'info'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/info/:id'}
                  exact
                  render={(props) => <Page.Billing {...props} redux={redux} urlSegment={'info'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans'}
                  exact
                  render={(props) => <Page.Billing {...props} redux={redux} urlSegment={'plans'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans/:id'}
                  exact
                  render={(props) => <Page.Billing {...props} redux={redux} urlSegment={'plans'} />}
                />
              )}
              {isAdminWithSubscription() && (
                <Route
                  path={'/billing/plans/:id/:type'}
                  exact
                  render={(props) => <Page.Billing {...props} redux={redux} urlSegment={'plans'} />}
                />
              )}
              <Route path="/socialAuth" component={AuthComplete} />
              <Route path={'/optout'} exact render={(props) => <Page.OptOut />} />
              <Route path={'/optoutAutomatic'} exact render={(props) => <Page.OptOutFromEmail />} />
              <Route path="/">
                <Redirect to="/home" />
              </Route>
            </Switch>
          )}
          <Switch>
            {nextStep === 0 && (
              <Route
                path={'/emailvalidation'}
                exact
                render={(props) => <Page.SignupCodeConfirmation {...props} redux={redux} />}
              />
            )}
            {nextStep < 3 && (
              <Route
                path={'/welcome'}
                exact
                render={(props) => <Page.Onboarding {...props} redux={redux} />}
              />
            )}

            <Route path="/">
              {nextStep === 0 ? (
                <Redirect to="/emailvalidation" />
              ) : nextStep === 1 ? (
                <Redirect to="/welcome" />
              ) : nextStep === 2 ? (
                <Redirect to="/welcome" />
              ) : null}
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </TrackingEvents>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setNavBarToggle: (navToggle) => {
    dispatch({ type: actions.NAVBAR_TOGGLE, navToggle });
  },
  resetCredits: () => {
    getCredits().then(({ data, statusCode }) => {
      dispatch({ type: actions.GET_CREDITS, payload: data });
    });
  },
  resetRedux: async () => {
    const [{ data: dataCredits }, { data: dataUser }, { data: dataOrg }, { data: dataRemaining }] =
      await Promise.all([getCredits(), getUser(), getOrganization(), getRemaining()]);
    dispatch({
      type: reduxActions.GET_ORGANIZATION,
      payload: dataOrg,
    });

    dispatch({
      type: reduxActions.GET_CREDITS,
      payload: dataCredits,
    });
    dispatch({
      type: reduxActions.GET_USER,
      payload: dataUser,
    });
    return dataOrg;
  },
});

const mapStateToProps = (state) => {
  return { redux: state };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
