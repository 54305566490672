import React, { useContext, useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { priceCalc } from '../../../../services/utils';
import actions from '../../store/billing.actions';
import { getOrganization } from '../../../../services/api/organization.api';
import { getSubscriptionInfos } from '../../../../services/api/credits.api';
import { BillingContext } from '../../store/billing.store';
import { FormattedDate, FormattedMessage, injectIntl, useIntl } from 'react-intl';
import WarningSection from '../WarningSection/WarningSection';
import { formatSubscribeInfo } from '../../../../services/utils/tools';
import './ManageUsersModal.css';
function ManageUsersModal({
  toggle,
  setToggle,
  onProceed,
  infos,
  organizationCredits,
  loadingAddUsers,
  setLoadingAddUsers,
}) {
  const intl = useIntl();
  const [value, setValue] = useState((infos && infos.plan && infos.plan.numberOfSeats + 1) || 1);
  const [error, setError] = useState(null);
  const [CouponError, setCouponError] = useState(null);
  const [planWithNewLicences, setPlanWithNewLicences] = useState(infos.plan);

  const { state } = useContext(BillingContext);
  const VAT_PERCENTAGE = 1.2;
  /* useEffect(() => {

  }, [value]);*/
  const getSubscribeInfoData = async (numberOfSeats) => {
    setLoadingAddUsers(true);
    const { data, statusCode } = await getSubscriptionInfos({
      numberOfSeats,
      numberOfMonths: infos.plan.numberOfMonths,
      isAnnual: infos.plan.numberOfMonths === 12,
      planId: infos.plan.planId,
      country: state.checkoutForm.country,
    });
    setLoadingAddUsers(false);
    if (data.message && statusCode == 200) {
      return setCouponError(data.messageTranslated || data.message);
    }
    if (statusCode == 200) {
      setError(null);
      return setPlanWithNewLicences({
        ...infos.plan,
        ...formatSubscribeInfo(data),
      });
    }
    if (statusCode !== 200) {
      return setError(
        data.message !== 'Cannot upgrade to the same plan' &&
          (data.messageTranslated || data.message),
      );
      setCouponError(null);
    }
  };
  const handleProceed = async () => {
    const { data, statusCode } = await getOrganization();
    const members = data.organization.members;
    if (value < infos.plan.numberOfSeats) {
      if (value >= members.length) {
        onProceed(value, planWithNewLicences, true);
      } else {
        setError(
          'you must delete ' +
            (value - infos.plan.numberOfSeats) +
            ' members of your organization to be able to continue',
        );
      }
    } else {
      onProceed(value, planWithNewLicences);
    }
  };
  const increment = async () => {
    if (value < 2000) {
      setValue(value + 1);
      await getSubscribeInfoData(value + 1);
    } else {
      setValue(value);
      await getSubscribeInfoData(value);
    }
  };
  const decrement = async () => {
    if (value > 1) {
      setValue(value - 1);
      await getSubscribeInfoData(value - 1);
    } else {
      setValue(value);
      await getSubscribeInfoData(value);
    }
  };
  return (
    <Modal show={toggle} onHide={setToggle} className=" fade manage-user-modal" id="manage-user">
      <Modal.Header closeButton className="modal-header border-0">
        <div className="modal-inner-header">
          <div className="profile-info">
            <h5 className="heading mb-8">
              <FormattedMessage id={'billing.manageUsers'} />
            </h5>
            <p className="descr-ui">
              <FormattedMessage id={'billing.youCanAdd'} />
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="manage-user-ui">
          <div className="your-plan-ui">
            <div className="left-ui">
              <label>
                <FormattedMessage id={'billing.yourCurrentPlan'} />
              </label>
              <h5>{infos.plan.name}</h5>
              <p>
                {infos.plan.pricePerSeat} € <FormattedMessage id={'billing.userMonth'} />
                {infos.plan.numberOfMonths == 12 && (
                  <>
                    {' '}
                    ·{' '}
                    {(
                      (infos.plan.pricePerSeat - infos.plan.recurringDiscount) *
                      infos.plan.numberOfSeats *
                      12 *
                      VAT_PERCENTAGE
                    ).toFixed(2)}{' '}
                    € <FormattedMessage id={'billing.billedAnnually'} />
                  </>
                )}
                <br />
                <span className={'plan-credits-rm'}>
                  <span>
                    <FormattedMessage
                      id={'phoneCredits'}
                      values={{
                        value:
                          infos.plan.phoneCredits.perSeat *
                          infos.plan.numberOfMonths *
                          infos.plan.numberOfSeats,
                      }}
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      id={'personalEmailCredits'}
                      values={{
                        value:
                          infos.plan.personalEmailCredits.perSeat *
                          infos.plan.numberOfMonths *
                          infos.plan.numberOfSeats,
                      }}
                    />
                  </span>

                  <span>
                    <FormattedMessage
                      id={'exportCredits'}
                      values={{
                        value:
                          infos.plan.exportCredits.perSeat *
                          infos.plan.numberOfMonths *
                          infos.plan.numberOfSeats,
                      }}
                    />
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="modify-user-block">
          <div className="left-ui">
            <span className="icon-user">
              <img src="/assets/images/ic-modify.png" alt="" />
            </span>
            <h6>
              {' '}
              <FormattedMessage id={'billing.modifyUsers'} />
            </h6>
            <p>
              {' '}
              <FormattedMessage id={'billing.typeOrChange'} />
            </p>
          </div>
          <div className="right-ui">
            <div className="quantity-btn-ui">
              <button type="button" id="sub2" className="quty-btn sub" onClick={decrement}>
                <span className="icon-i_minus">
                  <img src="/assets/images/ic-minus.svg" alt="" />
                </span>
              </button>
              <input
                type="number"
                id="2"
                value={value}
                onChange={(e) => {
                  if (parseInt(e.target.value) <= 1 || !e.target.value) {
                    setValue(parseInt(1));
                    return;
                  }
                  if (parseInt(e.target.value) > 2000) {
                    setValue(parseInt(2000));
                    return;
                  }
                  setValue(parseInt(e.target.value));
                }}
                min="1"
                max="2000"
              />
              <button type="button" id="add2" className="quty-btn add" onClick={increment}>
                <span className="icon-ic_add">
                  <img src="/assets/images/ic-add.svg" alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      {CouponError && (
        <div
          className="btn-grp text-center  error-warning  kaspr-form-text-error"
          style={{ width: '90%', top: '10px' }}
        >
          <p style={{ margin: 0, fontWeight: '600' }}>You’re about to lose your coupon ! </p>
          {CouponError + ' '}
        </div>
      )}
      {error && (
        <div
          className="btn-grp text-center  error  kaspr-form-text-error"
          style={{ width: '90%', top: '10px' }}
        >
          {error + ' '}
        </div>
      )}
      {organizationCredits.pending &&
        organizationCredits.pending.plan &&
        organizationCredits.pending.plan.planId !== '0' && (
          <WarningSection
            textElement={
              <p>
                <FormattedMessage
                  id={'billing.pendingPlanWarningText'}
                  values={{
                    br: <br />,
                  }}
                />
              </p>
            }
          />
        )}
      <Modal.Footer className="border-0">
        <a href="#" className="btn4 mr14 btn48" onClick={setToggle}>
          <FormattedMessage id={'cancel'} />
        </a>
        {value == infos.plan.numberOfSeats || value < infos.plan.minSeats || error ? (
          <a
            className="btn2 btn48"
            onClick={() => false}
            style={{ background: '#ccc', color: 'white' }}
          >
            <FormattedMessage id={'billing.proceed'} />
          </a>
        ) : (
          <a
            href="#"
            className="btn1 btn48"
            onClick={loadingAddUsers ? () => null : handleProceed}
            style={{ minWidth: 130, textAlign: 'center' }}
          >
            {loadingAddUsers ? (
              <img src="/assets/images/loading.gif" alt="" width={17} />
            ) : (
              <FormattedMessage id={'billing.proceed'} />
            )}
          </a>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ManageUsersModal;
