import React from 'react';
import actions from './Search.actions';

const SearchContext = React.createContext();

const searchInitialState = {
  toggle: false,
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE:
      return {
        ...state,
        toggle: !state.toggle,
      };
    default:
      return state;
  }
};

export { SearchContext, searchInitialState, searchReducer, actions };
