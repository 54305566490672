/* global chrome */
import React, { useEffect, useState } from 'react';
import { ConfirmPhonePopUp, PhoneValidation } from './PhoneValidation/PhoneValidation';
import InviteFriend from './InviteFriend/InviteFriend';
import {
  changePhone,
  isPhoneTokenSent,
  phoneVerificationRequest,
  skipSteps,
  validatePluginInstallation,
} from '../../../services/api/auth.api';
import { CHROME_STORE, EXTENSION_ID, IS_PROD } from '../../../constants';
import { toast, ToastContainer } from 'react-toastify';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import './Onboarding.css';
import OnboardingFooter from '../common/OnboardingFooter/OnboardingFooter';
import RedirectionPage from './RedirectionPage/RedirectionPage';

function OnBoardingSteps(props) {
  const [phoneValidationModalToggle, setPhoneValidationModalToggle] = useState(false);
  const [confirmPhonePopupToggle, setConfirmPhonePopupToggle] = useState(false);
  const [inviteModalToggle, setInviteToggle] = useState(false);
  const [steps, setSteps] = useState([]);
  const [phone, setPhone] = useState(false);
  let [phoneSmsError, setPhoneSmsError] = useState(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (props.profile && props.profile.steps) {
      setSteps(props.profile.steps);
    }
    if (window.chrome && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          action: 'isKasprExtensionInstalled',
        },
        async (response, err) => {
          if (!response) {
            setIsExtensionInstalled(false);
          } else {
            const allSteps = props.profile.steps.map((step) => {
              if (step.id === 'pluginInstallation') {
                if (step.done) {
                  return step;
                }
                step.done = true;
                validatePluginInstallation();
              }
              return step;
            });
            setSteps(allSteps);
            setIsExtensionInstalled(true);
          }
        },
      );
    }
  }, [props.profile]);

  const handleOnClick = async (type) => {
    switch (type) {
      case 'phoneValidation':
        let body = await isPhoneTokenSent();
        if (body.data.success) {
          setPhoneValidationModalToggle(true);
          setConfirmPhonePopupToggle(false);
          break;
        }
        setPhoneValidationModalToggle(false);
        setConfirmPhonePopupToggle(true);
        break;
      case 'confirm_phone':
        if (phone) {
          await changePhone(phone);
        }
        let { data, statusCode } = await phoneVerificationRequest();
        if (statusCode >= 400 && data && data.message) {
          setPhoneSmsError(data.message.replace(" 'To'", ''));
          break;
        }
        setPhoneSmsError(null);
        setConfirmPhonePopupToggle(false);
        setPhoneValidationModalToggle(true);
        break;
      case 'edit_phone':
        setConfirmPhonePopupToggle(true);
        setPhoneValidationModalToggle(false);
        break;
      case 'invite':
        setInviteToggle(true);
        break;
      case 'linkedin_connect':
        isExtensionInstalled
          ? window.open(
              'https://www.linkedin.com/in/anne-charlotte-lazou/?synchro=kaspr&isFirstKasprSynchro=true',
            )
          : (window.location.href = CHROME_STORE);
        break;
      case 'plugin':
        window.location.href = CHROME_STORE;
        break;
    }
  };

  const handleSkipSteps = async () => {
    // const results = await skipSteps();
    window.location = '/home';
  };
  const handleProceed = async () => {
    window.location = '/home';
  };
  const checkNextStep = () => {
    if (props.profile && props.profile.nextStep) {
      if (props.profile.nextStep === 1) return true;
      return props.profile.nextStep === 2;
    }
  };
  return (
    <div id="onboarding-ctn">
      {' '}
      <OnBoardWrapper
        handleSkipSteps={handleSkipSteps}
        handleProceed={handleProceed}
        nextStep={props.profile && props.profile.nextStep}
        intl={props.intl}
      >
        {props.profile.phone && (
          <ConfirmPhonePopUp
            setConfirmPopupToggle={setConfirmPhonePopupToggle}
            toggle={confirmPhonePopupToggle}
            onPhoneChange={setPhone}
            phone={phone ? phone : props.profile.phone}
            onClick={handleOnClick}
            error={phoneSmsError}
          />
        )}
        {props.profile.phone && (
          <PhoneValidation
            handleCloseModal={() => setPhoneValidationModalToggle(false)}
            toggle={phoneValidationModalToggle}
            phone={phone ? phone : props.profile.phone}
            resetCredits={props.resetCredits}
            showToastMessage={(response) => {
              response === 'success'
                ? toast.success(
                    props.intl.formatMessage({
                      id: 'auth.codeSuccessfullyResent',
                    }),
                  )
                : toast.error(response);
            }}
            onClick={handleOnClick}
          />
        )}
        <InviteFriend
          handleCloseModal={() => setInviteToggle(false)}
          toggle={inviteModalToggle}
          showToastMessage={(response) => {
            response === 'success'
              ? toast.success(
                  props.intl.formatMessage({
                    id: 'auth.invitationsSuccessfullySent',
                  }),
                )
              : toast.error(response);
          }}
        />
        <RedirectionPage
          isExtensionInstalled={isExtensionInstalled}
          handleOnClick={handleOnClick}
        />
        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </OnBoardWrapper>
      <OnboardingFooter />
    </div>
  );
}

function OnBoardWrapper({ children }) {
  return (
    <div className="onboard-content-ui">
      <div className="container-fluid">{children}</div>
    </div>
  );
}

OnBoardingSteps = injectIntl(OnBoardingSteps);
export { OnBoardingSteps };
